import { toast } from "react-toastify";

function toasterService(status, message, description) {
  if (typeof message === "string") {
    // if (description) {
    //   toasterPayLoad[`description`] = description;
    // }
    const toastOptions = {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      description,
    };
    const toastOptionsError = {
      position: "bottom-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "colored",
    };
    switch (status) {
      case toasterType.success:
        toast.success(message, toastOptions);
        break;
      case toasterType.warning:
        toast.warn(message, toastOptions);
        break;
      case toasterType.notify:
        toast.info(message, toastOptions);
        break;
      case toasterType.danger:
        toast.error(message, toastOptionsError);
        break;
      default:
        break;
    }
  } else {
    // alert(message);
    console.log(message);
  }
}

const toasterType = {
  success: "success",
  notify: "notify",
  warning: "warning",
  danger: "danger",
};

export { toasterService, toasterType };
