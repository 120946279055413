import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiRunner } from "../../services/communicator";
import { restaurantListWithPagenationOptions } from "../../services/standard";

export const getShops = createAsyncThunk(
  "seeAll/restaurentProductRequestOptions",
  async (obj) => {
    const requestOptions = Object.assign(
      {},
      restaurantListWithPagenationOptions
    );
    requestOptions.url = `${restaurantListWithPagenationOptions.url}/${
      obj.lat
    }/${obj.long}?${"page="}${obj.page}`;
    const response = await ApiRunner(requestOptions);
    return response;
  }
);

export const seeAllSlice = createSlice({
  name: "seeAll",
  initialState: {
    status: "idle",
    result: {},
  },

  extraReducers: (builder) => {
    builder
      .addCase(getShops.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getShops.fulfilled, (state, action) => {
        state.status = "complete";
        state.result = action.payload;
      });
  },
});

export const selectSeeAllRestaurent = (state) => state.seeAll;

export default seeAllSlice.reducer;
