import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiRunner } from "../../services/communicator";
import { resetLoginPasswordOptions } from "../../services/standard";
import { toasterService, toasterType } from "../../services/toasterService";

export const postResetPassword = createAsyncThunk(
  "resetpassword/postResetPassword",
  async (obj) => {
    const requestOptions = resetLoginPasswordOptions;
    requestOptions[`body`] = JSON.stringify(obj);
    const response = await ApiRunner(requestOptions);
    toasterService(
      response.status === true ? toasterType.success : toasterType.danger,
      response?.message
    );
    return response;
  }
);

export const resetpasswordSlice = createSlice({
  name: "reset",
  initialState: {
    status: "idle",
    user: { status: false, message: "", data: {} },
  },

  extraReducers: (builder) => {
    builder
      .addCase(postResetPassword.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postResetPassword.fulfilled, (state, action) => {
        state.status = "idle";
        state.user = action.payload;
      });
  },
});

export const resetPasswordData = (state) => state.reset;
export default resetpasswordSlice.reducer;
