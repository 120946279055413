import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import { toasterService, toasterType } from "./services/toasterService";
const measurementId = process.env.REACT_APP_FB_MEASUREMENT_ID;
const firebaseConfig = {
  apiKey: "AIzaSyBavueOTiwu_9axYGdUPQx8sFQTVebRg1Q",
  authDomain: "edr-user-apps.firebaseapp.com",
  projectId: "edr-user-apps",
  storageBucket: "edr-user-apps.appspot.com",
  messagingSenderId: "824693882806",
  appId: "1:824693882806:web:faace310a2aeb3142d0b17",
  measurementId: measurementId,
};
// Qa config
// const firebaseConfig = {
//   apiKey: "AIzaSyBavueOTiwu_9axYGdUPQx8sFQTVebRg1Q",
//   authDomain: "edr-user-apps.firebaseapp.com",
//   projectId: "edr-user-apps",
//   storageBucket: "edr-user-apps.appspot.com",
//   messagingSenderId: "824693882806",
//   appId: "1:824693882806:web:97b49c09ba030d702d0b17",
//   measurementId: "G-8X5PRR4ED7",
// };
// prod config
// const firebaseConfig = {
//   apiKey: "AIzaSyBavueOTiwu_9axYGdUPQx8sFQTVebRg1Q",
//   authDomain: "edr-user-apps.firebaseapp.com",
//   projectId: "edr-user-apps",
//   storageBucket: "edr-user-apps.appspot.com",
//   messagingSenderId: "824693882806",
//   appId: "1:824693882806:web:cc41c21eca4be7d12d0b17",
//   measurementId: "G-4EHS20Z5LK"
// };
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
const analytics = getAnalytics(firebaseApp);
const perf = getPerformance(firebaseApp);
export const getFBToken = (setTokenFound, setFbToken) => {
  return getToken(messaging, {
    vapidKey:
      "BJpNs0qV4BvTc4AuOqT6W8ZeIL9VezcF2UB6EjQEJcARe0CFknNmf7XYGDWch4wvzFjUg_pR4g0JR0LabkhFb60",
  })
    .then((currentToken) => {
      // console.log(
      //   "🚀 ~ file: firebaseVaas.js ~ line 45 ~ .then ~ currentToken",
      //   currentToken
      // );
      if (currentToken) {
        setTokenFound(true);
        setFbToken(currentToken);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        setTokenFound(false);
        setFbToken("");
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      setTokenFound(false);
      setFbToken("");
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log(
        "🚀 ~ file: firebaseVaas.js ~ line 240 ~ onMessage ~ payload",
        payload
      );
      toasterService(
        toasterType.notify,
        payload.notification.body,
        payload.notification.title
      );
      resolve(payload);
    });
  });
