import { Pill } from "evergreen-ui";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCartCountState } from "../features/restaurant/headerSlice";
import { getToken } from "../services/standard";

const FloatingCartComponent = (props) => {
  const getCartCount = useSelector(getCartCountState);
  const cartCountValue = getCartCount.result;
  const navigate = useNavigate();
  const token = getToken();

  const cart = () => {
    navigate(`/cart`);
  };

  return (
    <>
      {cartCountValue?.data[0]?.totalQuantity >= 1 ? (
        <div className="row gotoCartFloating">
          <div className="col-6 text-center" style={{ minWidth: "180px" }}>
            <div id="DivForHoverItem">
              <button
                type="submit"
                className="btn secondary-bg-color text-white  mb-3 button-hover"
                onClick={cart}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="26"
                  fill="currentColor"
                  className="bi bi-bag-check"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.854 8.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                  />
                  <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z" />
                </svg>{" "}
                Go to Cart
                {token && cartCountValue?.data[0]?.totalQuantity ? (
                  <Pill
                    display="inline-flex"
                    margin={8}
                    style={{
                      marginLeft: "5px",
                      marginTop: "0px",
                      backgroundColor: "red",
                      color: "white",
                    }}
                  >
                    {cartCountValue?.data[0]?.totalQuantity}
                  </Pill>
                ) : (
                  <></>
                )}
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>

  );
};
export default FloatingCartComponent;
