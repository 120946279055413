import React, { useEffect, useState } from "react";
import profile from "../images/profile.png";
import User from "../images/User.png";
import place from "../images/place.png";
import phone from "../images/phone.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Pane, SideSheet, Spinner } from "evergreen-ui";
import Rectangle5 from "../images/Rectangle5.png";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import {
  notLoggedInErrorMessage,
  notLoggedInErrorTitle,
} from "../services/standard";
import {
  getProfile,
  selectProfile,
  updateProfile,
} from "../features/sign-in/profileSlice";
import { selectPrimaryAddress } from "../features/restaurant/addressSlice";
import { getOrder, selectOrder } from "../features/order/orderSlice";
import moment from "moment";
import OrderDetailComponent from "../components/OrderDetailComponent";
import { toasterService, toasterType } from "../services/toasterService";

const Profile = () => {
  const [isLoading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const dispatch = useDispatch();
  const profileValues = useSelector(selectProfile);
  const profileData = profileValues.profileUser;
  const [name, setName] = useState(profileData?.data?.name);
  const [mobile, setMobile] = useState(profileData?.data?.mobile);
  const [countryCode, setCountryCode] = useState(
    profileData?.data?.countryCode
  );
  const navigate = useNavigate();
  const selectPrimaryAddressApiData = useSelector(selectPrimaryAddress);
  const selectPrimaryAddressApi = selectPrimaryAddressApiData?.result;
  const [isNameUpdate, setIsNameUpdate] = useState(false);
  const [isMobileNumUpdate, setIsMobileNumUpdate] = useState(false);
  const [orderId, setOrderId] = useState("");
  const orderData = useSelector(selectOrder);
  const isNextOrderPage = orderData?.isNextPage;
  const orders = orderData?.resultOrder;
  const [showOrderDetail, setShowOrderDetail] = useState(false);
  const bc = new BroadcastChannel("vaas_notificationsForEDRUser");

  let errors = {};
  let isValid = true;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(getOrder({ page: 1 }));
  }, [dispatch]);

  useEffect(() => {
    setName(profileData?.data?.name);
    setMobile(profileData?.data?.mobile);
    setCountryCode(profileData?.data?.countryCode);
  }, [
    profileData?.data?.name,
    profileData?.data?.mobile,
    profileData?.data?.countryCode,
  ]);

  useEffect(() => {
    bc.onmessage = (messageEvent) => {
      setLoading(true);
      dispatch(getOrder({ page: 1 }));
    };
  }, [bc.onmessage]);

  useEffect(() => {
    if (
      profileValues.status === "complete" &&
      orderData?.status === "complete" &&
      selectPrimaryAddressApiData?.status === "complete"
    ) {
      setLoading(false);
    }
  }, [
    profileValues.status,
    orderData?.status,
    selectPrimaryAddressApiData?.status,
  ]);

  const updatedProfile = () => {
    if (isNameUpdate === false) {
      setIsNameUpdate(true);
    } else {
      setIsNameUpdate(false);
      dispatch(updateProfile({ name: name }));
      dispatch(getProfile());
    }
  };

  function mobileValidation() {
    var pattern = new RegExp(/^[0-9\b]+$/);
    if (!pattern.test(mobile)) {
      isValid = false;
      errors["mobile"] = "Please enter only number.";
    } else if (mobile.length <= 5) {
      isValid = false;
      errors["mobile"] = "Please enter valid number.";
    }
    return isValid;
  }

  const updatedProfileMob = () => {
    if (isMobileNumUpdate === false) {
      setIsMobileNumUpdate(true);
    } else {
      const validationResult = mobileValidation();
      if (validationResult) {
        setIsMobileNumUpdate(false);
        dispatch(
          updateProfile({
            mobile: mobile,
            countryCode: countryCode,
          })
        );
      } else {
        toasterService(toasterType.danger, errors?.mobile);
      }
    }
  };

  function moreOrders() {
    dispatch(getOrder({ page: pageNumber + 1 }));
    setPageNumber(pageNumber + 1);
  }

  function orderDetail(order_id) {
    setOrderId(order_id);
  }

  return (
    <>
      {isLoading ? (
        <div className="bg-white pt-5 mt-5">
          <div className="container rs-page">
            <div className="row">
              <Pane>
                <Spinner marginX="auto" marginY={120} />
              </Pane>
            </div>
          </div>
        </div>
      ) : profileData?.reauth ? (
        <div className="bg-white pt-5 mt-5">
          <div className="container rs-page">
            <div className="row">
              <Pane>
                <Dialog
                  isShown={true}
                  title={notLoggedInErrorTitle}
                  intent="success"
                  hasClose={false}
                  hasCancel={true}
                  confirmLabel="Login"
                  onConfirm={(close) => {
                    navigate("/sign-in");
                  }}
                  shouldCloseOnOverlayClick={false}
                  shouldCloseOnEscapePress={false}
                >
                  {notLoggedInErrorMessage}
                </Dialog>
              </Pane>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="container-fluid bg-white pt-5 mt-5 pb-5">
            <div className="row">
              <div className="col-md-12">
                <div className="row gutter-0 justify-content-center px-5">
                  <div className="col-lg-3 col-md-5 me-5">
                    <div className="row mx-auto">
                      <h4 className="float-start pt-4 ">{name}'s Profile</h4>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6"></div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="row gutter-0 justify-content-center pb-5 ps-5 pe-5">
                  <div className="col-lg-4 col-md-10 me-5 mt-4">
                    <div className="row mx-auto">
                      <div className="col-md-12 p-0">
                        <div className="card edr-card-radius profile-edr-card-border profileCard">
                          <div className="card-body address-gray edr-card-radius">
                            <img
                              src={profile}
                              className="image-fluid center mt-3"
                              alt=""
                            />
                            <div className="row my-4 d-flex justify-content-center">
                              <div className="col-1">
                                <img
                                  src={User}
                                  className="image-fluid"
                                  alt=""
                                />
                              </div>
                              <div className="col-8">
                                {isNameUpdate ? (
                                  <input
                                    type="text"
                                    value={name}
                                    className="form-control text-line"
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                ) : (
                                  name
                                )}
                              </div>
                              <div className="col-3">
                                <span
                                  type="button"
                                  name="description"
                                  className="float-end edr-anchor"
                                  onClick={() => updatedProfile()}
                                >
                                  {isNameUpdate ? "update" : "change"}
                                </span>
                              </div>
                            </div>
                            <hr />
                            <div className="row mt-4 mb-4 d-flex justify-content-center">
                              <div className="col-1">
                                <img
                                  alt=""
                                  src={phone}
                                  className="image-fluid"
                                />
                              </div>
                              <div className="col-8 d-flex justify-content-start">
                                {isMobileNumUpdate ? (
                                  <>
                                    <IntlTelInput
                                      defaultValue={
                                        countryCode
                                          ? mobile
                                            ? `${countryCode} ${mobile}`
                                            : `${countryCode}`
                                          : ``
                                      }
                                      inputClassName="form-control text-line"
                                      onSelectFlag={(
                                        currentNumber,
                                        selectedCountryData,
                                        fullNumber,
                                        isValid
                                      ) => {
                                        setCountryCode(
                                          `${selectedCountryData?.dialCode}`
                                        );
                                      }}
                                    />
                                    <input
                                      type="text"
                                      value={mobile}
                                      className="form-control text-line"
                                      onChange={(e) =>
                                        setMobile(e.target.value)
                                      }
                                    />
                                  </>
                                ) : countryCode ? (
                                  mobile ? (
                                    `${countryCode} ${mobile}`
                                  ) : (
                                    `${countryCode}`
                                  )
                                ) : (
                                  ``
                                )}
                              </div>
                              <div className="col-3">
                                <span
                                  type="button"
                                  className="float-end edr-anchor"
                                  onClick={() => updatedProfileMob()}
                                >
                                  {isMobileNumUpdate ? "update" : "change"}
                                </span>
                              </div>
                            </div>
                            <hr />
                            <div className="row mt-4 mb-4 d-flex justify-content-center">
                              {Object.keys(
                                selectPrimaryAddressApi?.data?.address
                              ).length !== 0 ? (
                                <>
                                  <div className="col-1">
                                    <img
                                      alt=""
                                      src={place}
                                      className="image-fluid"
                                    />
                                  </div>
                                  <div className="col-8">
                                    <span className="text-center">
                                      {
                                        selectPrimaryAddressApi?.data
                                          ?.address[0]?.house
                                      }
                                      ,{" "}
                                      {
                                        selectPrimaryAddressApi?.data
                                          ?.address[0]?.area
                                      }{" "}
                                      <br />
                                      {
                                        selectPrimaryAddressApi?.data
                                          ?.address[0]?.city
                                      }
                                      ,{" "}
                                      {
                                        selectPrimaryAddressApi?.data
                                          ?.address[0]?.locality
                                      }{" "}
                                      <br />
                                      <br />
                                      Mobile:
                                      {
                                        selectPrimaryAddressApi?.data
                                          ?.address[0]?.mobile
                                      }
                                    </span>
                                  </div>
                                  <div className="col-3">
                                    <span
                                      type="button"
                                      className="float-end edr-anchor"
                                      onClick={() => {
                                        navigate(`/primaryaddresslist`);
                                      }}
                                    >
                                      change
                                    </span>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="col-1">
                                    <img
                                      alt=""
                                      src={place}
                                      className="image-fluid"
                                    />
                                  </div>
                                  <div className="col-6">
                                    <p>
                                      <b>No Address Found</b>
                                    </p>
                                  </div>
                                  <div className="col-4">
                                    <span
                                      type="button"
                                      className="float-end btn btn-sm btn-success text-white edr-anchor"
                                      onClick={() => {
                                        navigate(`/addaddress`);
                                      }}
                                    >
                                      Add Address
                                    </span>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-10 me-5 mt-5">
                    <div className="row mx-auto">
                      <div className="col-md-12 p-0">
                        <div className="card edr-card-radius profile-edr-card-border profileCard">
                          <div className="card-body edr-card-radius address-gray px-5">
                            <div className="row mx-1">
                              <div className="col-md-6">
                                <h5 className="fw-bold float-start pt-4 pb-2">
                                  Past Orders
                                </h5>
                              </div>
                              {orders.length === 0 ? (
                                <h3 className="fw-bold">No Orders Found</h3>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="row">
                              <div
                                className={`flex-row ${orders.length !== 0
                                    ? `overflowProfile cartProductsList`
                                    : ``
                                  }`}
                              >
                                {orders.length === 0 ? (
                                  <h4 className="text-center p-5">
                                    You don't have any orders
                                  </h4>
                                ) : (
                                  orders.map((order, key) => {
                                    return (
                                      <div key={key}>
                                        <div
                                          className="card p-4 mt-4 mb-4 cursor-pointer"
                                          onClick={() => {
                                            setShowOrderDetail(true);
                                            orderDetail(order?._id);
                                          }}
                                        >
                                          <div className="row">
                                            <div className="col-12 col-md-3">
                                              <img
                                                className="img-fluid d-block rs-page"
                                                src={
                                                  order?.shop_id?.shop_image !==
                                                    undefined &&
                                                    order?.shop_id?.shop_image
                                                    ? order?.shop_id?.shop_image
                                                    : Rectangle5
                                                }
                                                alt=""
                                              />
                                            </div>
                                            <div className="col-12 col-md-5 mb-5">
                                              <p className="fw-bold">
                                                {order?.shop_id !== undefined
                                                  ? order?.shop_id?.name
                                                  : ""}
                                              </p>
                                              <p className="fw-light">
                                                ORDER# {order?.order_id}
                                              </p>
                                              <button className="btn btn-warning float-start">
                                                Order Details
                                              </button>
                                            </div>
                                            <div className="col-12 col-md-4 mb-5">
                                              <p className="pb-1">
                                                Ordered on{" "}
                                                {moment(
                                                  order?.createdAt
                                                ).format("llll")}
                                              </p>
                                              <p className="fw-light">
                                                Order status:{" "}
                                                {order?.order_status}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col-12">
                                              <hr></hr>
                                            </div>
                                            <div className="col-12 col-md-8">
                                              {order?.order_items?.map(
                                                (item, key) => {
                                                  return `${key >= 1 ? ", " : ""
                                                    }
                                                ${`${item?.product_name} x ${item?.quantity}`}`;
                                                }
                                              )}
                                            </div>
                                            <div className="col-12 col-md-4">
                                              Total Paid: AED{" "}
                                              {order?.total_amount}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                )}
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="d-grid gap-2 col-md-6 mx-auto">
                                {isNextOrderPage ? (
                                  <button
                                    type="button"
                                    className="btn secondary-bg-color text-white"
                                    onClick={() => {
                                      moreOrders();
                                    }}
                                  >
                                    Show more orders
                                  </button>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <SideSheet
                isShown={showOrderDetail}
                onCloseComplete={() => setShowOrderDetail(false)}
                preventBodyScrolling
              >
                {orderId !== "" ? (
                  <>
                    <OrderDetailComponent order_id={orderId} />
                  </>
                ) : (
                  <></>
                )}
              </SideSheet>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default Profile;
