import { useEffect } from "react";

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="container-fluid mt-5 pt-5 pb-5 bg-white page-min-height">
        <div className="row">
          <div className="col-md-12">
            <h2  style={{fontSize: "42px"}} className="headingEditContact">CONTACT US</h2>
            <p className="fontEditContactUs">
              In order to resolve a complaint regarding the Site or the
              Marketplace Offerings or to receive further information regarding
              use of the Site or the Marketplace Offerings, please contact us at
            </p>
            <h5 className="contactUs">
              <br></br>
              Eat and Drink Group <br></br>
              Dubai United Arab Emirates<br></br>
              Post Box No: 65892<br></br>
              info@eatanddrink.ae
            </h5>
          </div>
        </div>
      </div>
      <div className="container bg-white"></div>
    </>
  );
};

export default ContactUs;
