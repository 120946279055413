import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  editAddress,
  getAddressById,
  getPrimaryAddress,
  selectAddressById,
} from "../features/restaurant/addressSlice";
import { useNavigate, useParams } from "react-router-dom";
import AddressGoogleMap from "../components/AddressGoogleMap";
import { statusIsGoogleMapsLoaded } from "../features/restaurant/headerSlice";
import { Autocomplete } from "@react-google-maps/api";
import { Pane, Spinner } from "evergreen-ui";
import { async } from "jshint/src/prod-params";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";

const ChangeAddress = () => {
  const selectPrimaryAddressApiData = useSelector(selectAddressById);
  const selectPrimaryAddressApi = selectPrimaryAddressApiData?.resultbyid;
  const address = selectPrimaryAddressApi?.data[0];
  const [autocomplete, setAutocomplete] = useState();
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [mobile, setMobile] = useState("");
  const [countryCode, setCountryCode] = useState("+971");
  const [area, setEditArea] = useState("");
  const [city, setEditCity] = useState("");
  const [landmark, setEditLandmark] = useState("");
  const [locality, setEditLocality] = useState("");
  const [house, setEditHouse] = useState("");
  const [country, setEditCountry] = useState("");
  const [googleResponse, setGoogleResponse] = useState("");
  const [googleAutocompleteValue, setGoogleAutocompleteValue] = useState("");

  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);

  const updateAddress = async (e, key) => {
    key = { key };
    e.preventDefault();
    await dispatch(
      editAddress({
        addressId: address?._id,
        house: house,
        city: city,
        area: area,
        landmark: landmark,
        locality: locality,
        mobile: mobile,
        countryCode: countryCode,
        longitude: long,
        latitude: lat,
        country: country,
        google_address_string: googleResponse,
      })
    );
    await dispatch(getPrimaryAddress());
    navigate(`/primaryaddresslist`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setEditHouse(address !== undefined ? address?.house : "");
    setEditLandmark(address !== undefined ? address?.landmark : "");
    setEditLocality(address !== undefined ? address?.locality : "");
    setEditCity(address !== undefined ? address?.city : "");
    setEditArea(address !== undefined ? address?.area : "");
    setLat(address !== undefined ? address?.latitude : "");
    setLong(address !== undefined ? address.longitude : "");
    setMobile(address !== undefined ? address?.mobile : "");
    setCountryCode(address !== undefined ? address?.countryCode : "");
    setEditCountry(address !== undefined ? address?.country : "");
  }, [dispatch, address]);

  useEffect(() => {
    dispatch(getAddressById({ addressId: params.id }));
  }, [dispatch, params.id]);

  function handleCallback(autocompleteResult) {
    setEditLandmark("");
    setEditLocality("");
    setEditCity("");
    setEditCountry("");
    setEditArea("");
    setEditHouse("");
    setLat(autocompleteResult?.geometry?.location.lat());
    setLong(autocompleteResult?.geometry?.location.lng());
    googleMapsResponseParser(autocompleteResult);
  }

  const googleMapsResponseParser = (autocompleteResult) => {
    let googleResponse = "";
    let landmark = "";
    let area = "";
    let locality = "";
    let streetAddress = "";
    let country = "";
    autocompleteResult?.address_components.forEach((address, key) => {
      key = { key };
      if (address.types.length > 0) {
        address.types.every((type) => {
          if (type === "plus_code") {
            return false;
          } else {
            if (googleResponse?.length > 0) {
              googleResponse += " - ";
            }
            googleResponse += address?.long_name;
          }
          if (type === "route") {
            landmark = address.long_name;
            return false;
          }
          if (type === "locality") {
            locality = address.long_name;
            return false;
          }
          if (type === "country" || type === "political") {
            country = address.long_name;
            return false;
          }
          if (
            type === "neighborhood" ||
            type === "sublocality" ||
            type === "sublocality_level_1"
          ) {
            area =
              area.length > 0
                ? `${area}, ${address.long_name}`
                : address.long_name;
            return false;
          }
          if (type === "street_number" || type === "street_address") {
            streetAddress =
              streetAddress.length > 0
                ? `${streetAddress}, ${address.long_name}`
                : address.long_name;
            return false;
          }
          return true;
        });
        if (landmark && landmark.length > 0) {
          setEditLandmark(landmark);
        }
        if (locality && locality.length > 0) {
          setEditLocality(locality);
          setEditCity(locality);
        }
        if (country && country.length > 0) {
          setEditCountry(country);
        }
        if (area && area.length > 0) {
          setEditArea(area);
        }
        if (streetAddress && streetAddress.length > 0) {
          setEditHouse(streetAddress);
        }
      }
    });
    setGoogleAutocompleteValue(googleResponse);
    setGoogleResponse(googleResponse);
  };

  const mapCallback = async (latLng) => {
    const geocoder = new window.google.maps.Geocoder();
    let geocoderResponse = await geocoder.geocode({ location: latLng });
    geocoderResponse = geocoderResponse?.results;
    if (geocoderResponse && geocoderResponse?.length > 0) {
      setLat(latLng?.lat);
      setLong(latLng?.lng);
      googleMapsResponseParser(geocoderResponse[0]);
    }
  };
  const map = (
    <AddressGoogleMap
      origin={{
        lat: lat,
        lng: long,
      }}
      mapCallback={mapCallback}
    />
  );

  const isLoaded = useSelector(statusIsGoogleMapsLoaded);

  const onLoad = (autocompleteParam) => {
    console.log("on load called");
    setAutocomplete(autocompleteParam);
    console.log("on load called", autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const autocompleteData = autocomplete.getPlace();
      handleCallback(autocompleteData);
    } else {
      console.log("Google maps is not loaded yet!");
      setLoading(false);
    }
  };

  return (
    <div>
      {isLoading ? (
        <div className="bg-white mt-5 pt-5 page-min-height">
          <div className="container rs-page">
            <div className="row">
              <Pane>
                <Spinner marginX="auto" marginY={120} />
              </Pane>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid py-5 mt-5 page-min-height">
          <div className="card mb-3">
            <div className="card-body">
              <h3 className="p-3 card-title">Edit Address</h3>
              <div className="row d-flex justify-content-around">
                <div className="col-md-5">
                  <div className="mb-3">
                    <input
                      type="hidden"
                      className="form-control text-line"
                      id="lat"
                      value={lat}
                      onChange={(e) => setLat(e?.target?.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="hidden"
                      className="form-control text-line"
                      id="lang"
                      value={long}
                      onChange={(e) => setLong(e?.target?.value)}
                    />
                  </div>
                  <div className="mb-3">
                    {isLoaded ? (
                      <Autocomplete
                        onLoad={onLoad}
                        onPlaceChanged={onPlaceChanged}
                        restrictions={{ country: "ae" }}
                      >
                        <input
                          type="text"
                          className="searchbox"
                          placeholder="Search your location"
                          onChange={(e) => {
                            setGoogleAutocompleteValue(e?.target?.value);
                          }}
                          value={googleAutocompleteValue}
                        />
                      </Autocomplete>
                    ) : (
                      <></>
                    )}
                  </div>

                  <form
                    className="was-validated"
                    onSubmit={(e) => updateAddress(e)}
                  >
                    <div className="mb-3">
                      <label htmlFor="city" className="form-label">
                        City
                      </label>
                      <input
                        className="form-control  text-line"
                        id="city"
                        onChange={(e) => setEditCity(e?.target?.value)}
                        value={city}
                        required
                      ></input>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="area" className="form-label">
                        Area
                      </label>
                      <input
                        className="form-control  text-line"
                        id="area"
                        onChange={(e) => setEditArea(e?.target?.value)}
                        value={area}
                        required
                      ></input>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="house" className="form-label">
                        House
                      </label>
                      <input
                        className="form-control  text-line"
                        id="house"
                        onChange={(e) => setEditHouse(e?.target?.value)}
                        value={house}
                        required
                      ></input>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="locality" className="form-label">
                        Locality
                      </label>
                      <input
                        className="form-control  text-line"
                        id="locality"
                        onChange={(e) => setEditLocality(e?.target?.value)}
                        value={locality}
                        required
                      ></input>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="landmark" className="form-label">
                        Landmark
                      </label>
                      <input
                        className="form-control  text-line"
                        id="landmark"
                        onChange={(e) => setEditLandmark(e?.target?.value)}
                        value={landmark}
                        required
                      ></input>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="country" className="form-label">
                        Country
                      </label>
                      <input
                        className="form-control  text-line"
                        id="country"
                        onChange={(e) => setEditCountry(e?.target?.value)}
                        value={country}
                        required
                      ></input>
                    </div>

                    <div className="mb-3">
                      <input
                        className="form-control  text-line"
                        type="hidden"
                        id="response"
                        onChange={(e) => setGoogleResponse(e?.target?.value)}
                        value={googleResponse}
                        required
                      ></input>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="mobile" className="form-label">
                        Mobile
                      </label>
                      <div className="d-flex justify-content-start">
                        <IntlTelInput
                          preferredCountries={["ae"]}
                          defaultValue={`${countryCode}${mobile}`}
                          inputClassName="form-control text-line"
                          onSelectFlag={(
                            currentNumber,
                            selectedCountryData,
                            fullNumber,
                            isValid
                          ) => {
                            setCountryCode(selectedCountryData?.dialCode);
                          }}
                        />
                        <input
                          className="form-control  text-line"
                          id="mobile"
                          onChange={(e) => setMobile(e.target.value)}
                          required
                          value={mobile}
                        ></input>
                      </div>
                    </div>

                    <div className="mb-3">
                      <button
                        type="submit"
                        className="btn secondary-bg-color text-white"
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>
                <div className="col-md-5">{map}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChangeAddress;
