import { Dialog, Pane, Pill, Spinner, WalkIcon } from "evergreen-ui";
import React, { useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { getCart, removeCartItem } from "../features/restaurant/cartSlice";
import { getCartCountASyncThunk } from "../features/restaurant/headerSlice";
import {
  getGuestProductById,
  getProductById,
  selectSearchProduct,
} from "../features/restaurant/searchSlice";
import delivery_icon from "../images/delivery_icon.png";
import Rectangle4 from "../images/Rectangle4.png";
import { getToken } from "../services/standard";
import QuantitySelectorCard from "./quantitySelector";

const Product = (props) => {
  const dispatch = useDispatch();
  const token = getToken();
  const productDetailsById = useSelector(selectSearchProduct);
  const [isShown, setIsShown] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  let getProductDetailsById = [];

  getProductDetailsById = token
    ? productDetailsById?.ProductResultById?.data[0]
    : productDetailsById?.guestProductResultById?.data[0];

  function getProductDetail(event) {
    if (isShown === false) {
      if (token) {
        event.preventDefault();
        event.stopPropagation();
        dispatch(getProductById({ productId: props?.productId }));
        setIsShown(true);
      } else {
        event.preventDefault();
        event.stopPropagation();
        dispatch(getGuestProductById({ productId: props?.productId }));
        setIsShown(true);
      }
    }
  }
  async function removeItem(event) {
    event.preventDefault();
    event.stopPropagation();
    setDeleteLoading(true);
    await dispatch(
      removeCartItem({
        cartId: props?.cartId,
        productId: props?.productId,
      })
    );
    await dispatch(getCart());
    await dispatch(getCartCountASyncThunk());
    setDeleteLoading(false);
  }

  return (
    <div
      className={
        props?.cardType === "cart"
          ? "col-lg-12 cursor-pointer"
          : "col-lg-6 cursor-pointer"
      }
    >
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-12 mt-3"
            onClick={(event) => getProductDetail(event, 1)}
          >
            {getProductDetailsById !== undefined &&
            getProductDetailsById?.status !== false ? (
              <Pane>
                <Dialog
                  isShown={isShown}
                  hasFooter={false}
                  hasHeader={false}
                  title="Dialog title"
                  onCloseComplete={() => setIsShown(false)}
                  confirmLabel="Custom Label"
                >
                  <div className="p-2 fs-6">
                    <h3 className="d-flex justify-content-center container pb-3">
                      {getProductDetailsById?.product_id?.name}
                    </h3>

                    <div className="about-product text-center mt-2">
                      <img
                        src={
                          getProductDetailsById?.product_id?.product_image
                            ? getProductDetailsById?.product_id?.product_image
                            : Rectangle4
                        }
                        alt=""
                        className="imageResponsivePopUp"
                      />
                      <div>
                        <h6 className="mt-3 text-black-50">
                          {getProductDetailsById?.product_id?.description}
                        </h6>
                      </div>
                    </div>
                    <div className="stats mt-2">
                      <div
                        className="mx-auto"
                        style={{ minWidth: "120px", maxWidth: "120px" }}
                      >
                        <QuantitySelectorCard
                          productId={props?.productId}
                          shopId={props?.shopId}
                          cartQuantity={props?.cartQuantity}
                          catId={props?.category}
                          outOfStock={props?.outOfStock}
                          restaurantPageNumber={props?.pageNumber}
                          isOpen={props?.isOpen}
                          lat={props?.lat}
                          long={props?.long}
                          search={props?.search}
                          paramValue={props?.search}
                          searchPageNumber={props?.searchPageNo}
                          categoryPageNumber={props?.catPageNo}
                        />
                      </div>
                      <div className="d-flex justify-content-center pt-3">
                        {getProductDetailsById?.shop?.name}
                      </div>
                      <div className="d-flex justify-content-between">
                        {getProductDetailsById?.is_discount &&
                        getProductDetailsById?.final_price &&
                        getProductDetailsById?.price &&
                        Number(getProductDetailsById?.final_price) <
                          Number(getProductDetailsById?.price) ? (
                          <span>Price</span>
                        ) : (
                          ""
                        )}

                        <span className="edr-dft-text-yellow">
                          {getProductDetailsById?.final_price &&
                          getProductDetailsById?.price &&
                          Number(getProductDetailsById?.final_price) <
                            Number(getProductDetailsById?.price) ? (
                            <>
                              <p
                                className="edr-dft-text-yellow"
                                style={{ margin: "0px" }}
                              >
                                <del>
                                  AED{" "}
                                  <span className="fw-bold">
                                    {getProductDetailsById?.price}
                                  </span>
                                </del>
                              </p>
                              {getProductDetailsById?.discounted &&
                              getProductDetailsById?.discount &&
                              getProductDetailsById?.discountType ? (
                                <p
                                  className="edr-dft-text-orange vaas-overlay-product"
                                  style={{ margin: "0px" }}
                                >
                                  {getProductDetailsById?.discount}{" "}
                                  {getProductDetailsById?.discountType ===
                                  "Percentage"
                                    ? " % "
                                    : " AED "}
                                  Off
                                </p>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </span>
                      </div>
                      {getProductDetailsById?.is_discount ? (
                        <div className="d-flex justify-content-between">
                          {getProductDetailsById?.discount > 0 ? (
                            <span>Discount</span>
                          ) : (
                            ""
                          )}

                          <span>
                            {props?.discounted &&
                            props?.discount &&
                            props?.discountType ? (
                              <p className="text-success">
                                {props?.discount}{" "}
                                {props?.discountType === "Percentage"
                                  ? " % "
                                  : " AED "}
                                Off
                              </p>
                            ) : (
                              <></>
                            )}
                          </span>
                        </div>
                      ) : (
                        <></>
                      )}

                      <div className="d-flex justify-content-between">
                        {getProductDetailsById?.is_discount ? (
                          <span>Final Price</span>
                        ) : (
                          <span>Price</span>
                        )}

                        <p className="text-success">
                          {props?.offerPrice ? (
                            <p className="text-success">
                              AED{" "}
                              <span className="fw-bold">
                                {props?.offerPrice}
                              </span>
                            </p>
                          ) : (
                            <p className="text-success">
                              AED{" "}
                              <span className="fw-bold">{props?.price}</span>
                            </p>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </Dialog>
              </Pane>
            ) : (
              ""
            )}

            <div
              className={
                props?.isOpen
                  ? props?.outOfStock
                    ? "card vaas-card-hover card-style bg-edr-grayout"
                    : "card vaas-card-hover card-style"
                  : "card vaas-card-hover card-style bg-edr-grayout"
              }
            >
              <div className="row justify-content-center">
                <div className="card-body col-lg-7 col-md-7 col-sm-7">
                  <div className="row justify-content-around">
                    <div className="col-7">
                      {!props?.isDeliverable ? (
                        <span className="badge badge-pill bg-edr-red text-white edr-card-radius fs-7 me-2">
                          {"Undeliverable"}
                        </span>
                      ) : (
                        <></>
                      )}
                      {!props?.isOpen ? (
                        <span className="badge badge-pill bg-edr-red text-white edr-card-radius fs-7">
                          Shop Closed
                        </span>
                      ) : (
                        <></>
                      )}

                      <h6 className="card-title fw-bold mt-2">
                        {props?.productName}
                      </h6>
                      <h6 className="card-text fw-bold edr-dft-text-gray">
                        {props?.shopName}
                      </h6>
                      <h6 className="card-text edr-dft-text-gray">
                        {props?.description}
                      </h6>
                      <h6 className="edr-dft-text-gray">
                        {props?.shopAddress}
                      </h6>

                      <div className="pt-4">
                        <img
                          alt="delivery icon"
                          src={delivery_icon}
                          className="img-fluid"
                        />
                      </div>
                      {props?.distance >= 0 ? (
                        <span
                          className="badge rounded-pill bg-warning text-dark mt-3"
                          style={{ width: "130px", height: "24px" }}
                        >
                          <WalkIcon> </WalkIcon>
                          {props?.distance > 20
                            ? `More than 20 Km`
                            : `${props?.distance}Km`}
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-5 text-center">
                      {props?.offerPrice &&
                      props?.price &&
                      Number(props?.offerPrice) < Number(props?.price) ? (
                        <>
                          <p
                            className="edr-dft-text-yellow"
                            style={{ margin: "0px" }}
                          >
                            <del>
                              AED{" "}
                              <span className="fw-bold">{props?.price}</span>
                            </del>
                          </p>
                          {props?.discounted &&
                          props?.discount &&
                          props?.discountType ? (
                            <p
                              className="edr-dft-text-orange vaas-overlay-product"
                              style={{ margin: "0px" }}
                            >
                              {props?.discount}{" "}
                              {props?.discountType === "Percentage"
                                ? " % "
                                : " AED "}
                              Off
                            </p>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                      {props?.offerPrice ? (
                        <p className="text-success">
                          AED{" "}
                          <span className="fw-bold">{props?.offerPrice}</span>
                        </p>
                      ) : (
                        <p className="text-success">
                          AED <span className="fw-bold">{props?.price}</span>
                        </p>
                      )}

                      {props?.isOpen ? (
                        <QuantitySelectorCard
                          productId={props?.productId}
                          shopId={props?.shopId}
                          cartQuantity={props?.cartQuantity}
                          catId={props?.catId}
                          restaurantPageNumber={props?.pageNumber}
                          categoryPageNumber={props?.catPageNo}
                          outOfStock={props?.outOfStock}
                          isOpen={props?.isOpen}
                          lat={props?.lat}
                          long={props?.long}
                          search={props?.search}
                          paramValue={props?.search}
                        />
                      ) : (
                        <></>
                      )}
                      {props?.cardType === "cart" ? (
                        deleteLoading ? (
                          <Pane>
                            <Spinner marginX="auto" />
                          </Pane>
                        ) : (
                          <Pill
                            className="mt-3"
                            display="inline-flex"
                            color="red"
                            style={{ height: "auto" }}
                          >
                            <AiOutlineDelete
                              size={35}
                              color="red"
                              style={{ cursor: "pointer" }}
                              onClick={(event) => {
                                removeItem(event);
                              }}
                            />
                          </Pill>
                        )
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-5 d-flex flex-row justify-content-center align-items-center text-center">
                  <img
                    alt="square wrapper"
                    className="imageResponsive"
                    src={props?.productImage ? props?.productImage : Rectangle4}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Product;
