import React, { useEffect, useState } from "react";
import street_food from "../images/street_food.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { postLogin, signInUser } from "../features/sign-in/signinSlice";
import { getPageUrl, removePopupState, setToken } from "../services/standard";
import { getCartCountASyncThunk } from "../features/restaurant/headerSlice";
import { Pane, Spinner } from "evergreen-ui";
import { toasterService, toasterType } from "../services/toasterService";
import IntlTelInput from "react-intl-tel-input";
import OtpField from "react-otp-field";
const SignIn = () => {
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [apiResult, setApiResult] = useState("");
  const [mobile, setMobile] = useState("");
  const [countryCode, setCountryCode] = useState("+971");
  const [mobileError, setMobileError] = useState(false);
  const [isRevealOtp, setIsRevealOtp] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  let loggedInUser = useSelector(signInUser);
  loggedInUser = loggedInUser?.user;
  const currentPageUrlName = getPageUrl();

  useEffect(() => {
    removePopupState();
    setLoading(false);
    if (Object.keys(loggedInUser).length !== 0) {
      if (loggedInUser?.status) {
        setIsRevealOtp(true);
      }
      setApiResult(loggedInUser?.message);
      if ("token" in loggedInUser?.data) {
        setToken(loggedInUser?.data?.token);
        dispatch(getCartCountASyncThunk());
        if (currentPageUrlName === null) {
          navigate(`/`);
        } else {
          setLoading(false);
          navigate(`/${currentPageUrlName}`);
        }
      }
    }
  }, [dispatch, loggedInUser, navigate, currentPageUrlName]);

  const resendOTP = (e) => {
    e?.preventDefault();
    setIsRevealOtp(false);
    setApiResult("");
    //  will prevent it from loading
    let error = false;
    const message = {
      mobile: "Mobile number not found",
      otp: "Please enter OTP",
      valid: "Please enter valid mobile number",
    };
    const isMobileValid = new RegExp(/^[0-9\b]+$/);
    if (mobile === "") {
      toasterService(toasterType.danger, message?.mobile);
      setMobileError(true);
      error = true;
    } else if (!isMobileValid) {
      toasterService(toasterType.danger, message?.valid);
      error = true;
    } else if (String(mobile).length <= 5) {
      toasterService(toasterType.danger, message?.valid);
      error = true;
    } else {
      setMobileError(false);
    }
    if (error === true) {
      return;
    }
    setLoading(true);
    dispatch(postLogin({ countryCode, mobile }));
  };
  const handleSubmit = (e) => {
    e?.preventDefault();
    setApiResult("");
    //  will prevent it from loading
    let error = false;
    const message = {
      mobile: "Mobile number not found",
      otp: "Please enter OTP",
      valid: "Please enter valid mobile number",
    };
    const isMobileValid = new RegExp(/^[0-9\b]+$/);
    if (mobile === "") {
      toasterService(toasterType.danger, message?.mobile);
      setMobileError(true);
      error = true;
    } else if (!isMobileValid) {
      toasterService(toasterType.danger, message?.valid);
      error = true;
    } else if (String(mobile).length <= 5) {
      toasterService(toasterType.danger, message?.valid);
      error = true;
    } else {
      setMobileError(false);
    }
    if (isRevealOtp) {
      if (otp === "") {
        toasterService(toasterType.danger, message?.otp);
        setOtpError(true);
        error = true;
      } else if (String(otp).length < 4) {
        toasterService(toasterType.danger, message?.valid);
        error = true;
      } else {
        setOtpError(false);
      }
    }
    if (error === true) {
      return;
    }
    setLoading(true);
    if (isRevealOtp) {
      dispatch(postLogin({ countryCode, mobile, otp: otp }));
    } else {
      dispatch(postLogin({ countryCode, mobile }));
    }
  };

  return (
    <div>
      <div className="container-fluid bg-white mt-5 pt-5 page-min-height">
        <div className="row">
          <div className="col-md-12 ">
            <div className="container-fluid mt-5 mb-5">
              <div className="row">
                <div className="col-md-6 edr-bg-gray edr-signup-form-radius p-md-5 p-sm-3">
                  <div className="col-md-9">
                    <h5 className="text-edr-primary mb-4">
                      Sign-in to continue
                    </h5>
                    <form onSubmit={(e) => handleSubmit(e)}>
                      <div className="mb-3">
                        <label
                          htmlFor="mobile"
                          className="form-label edr-dft-text-gray"
                        >
                          Mobile
                        </label>
                        <div class="d-flex">
                          <IntlTelInput
                            preferredCountries={["ae"]}
                            inputClassName="form-control text-line-mobile"
                            onSelectFlag={(
                              currentNumber,
                              selectedCountryData,
                              fullNumber,
                              isValid
                            ) => {
                              setCountryCode(
                                `${selectedCountryData?.dialCode}`
                              );
                            }}
                          />
                          <input
                            className="form-control  text-line"
                            id="mobile"
                            onChange={(e) => setMobile(e.target.value)}
                            required
                          ></input>
                        </div>
                        {mobileError === true ? (
                          <div className="text-danger">
                            Mobile number not found
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>

                      {isRevealOtp === true ? (
                        <>
                          <div className="mb-3">
                            <label
                              htmlFor="otp"
                              className="form-label edr-dft-text-gray"
                            >
                              OTP
                            </label>
                            {/* </div> */}
                            <div className="input-group mb-3">
                              <OtpField
                                value={otp}
                                onChange={setOtp}
                                numInputs={4}
                                onChangeRegex={/^([0-9]{0,})$/}
                                autoFocus
                                separator={<span></span>}
                                isTypeNumber
                                inputProps={{
                                  className: "otp-style",
                                  disabled: false,
                                }}
                              />
                            </div>
                            {otpError === true ? (
                              <div className="text-danger">
                                Please enter OTP
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="mb-2">
                            <span className="password-link">{apiResult}</span>
                          </div>
                          <div className="mb-5">
                            <div className="text-edr-primary">
                              <span className="SignUpText" onClick={resendOTP}>
                                {" "}
                                Resend OTP?
                              </span>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      <div className="mb-3">
                        {isLoading ? (
                          <Pane>
                            <Spinner marginX="auto" marginY={120} />
                          </Pane>
                        ) : (
                          <button className="btn secondary-bg-color text-white edr-card-radius width-full">
                            Sign in
                          </button>
                        )}
                      </div>
                    </form>
                    <div>
                      <p className="edr-dft-text-gray">
                        Don't have an account{" "}
                        <Link to="/signup" className="text-edr-primary">
                          <span className="SignUpText">Sign up</span>
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 secondary-bg-color edr-dft-image-radius p-5">
                  <img alt="" className="img-fluid center" src={street_food} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
