import { Button, Dialog, MapIcon, Pane, Spinner } from "evergreen-ui";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getOrderDetail, selectOrder } from "../features/order/orderSlice";
import {
  notLoggedInErrorMessage,
  notLoggedInErrorTitle,
} from "../services/standard";
const OrderDetail = () => {
  const [isLoading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const orderData = useSelector(selectOrder);
  const orderDetail = orderData?.resultOrderDetail;

  useEffect(() => {
    setLoading(true);
    dispatch(getOrderDetail({ orderId: params?.id }));
  }, [dispatch, params.id]);

  useEffect(() => {
    if (orderData?.status === "complete") {
      setLoading(false);
    }
  }, [orderData?.status]);

  return (
    <div>
      {isLoading ? (
        <div className="bg-white pt-5 mt-5 pb-5">
          <div className="container rs-page">
            <div className="row">
              <Pane>
                <Spinner marginX="auto" marginY={120} />
              </Pane>
            </div>
          </div>
        </div>
      ) : orderDetail?.reauth ? (
        <div className="bg-white pt-5 mt-5 pb-5">
          <div className="container rs-page">
            <div className="row">
              <Pane>
                <Dialog
                  isShown={true}
                  title={notLoggedInErrorTitle}
                  intent="success"
                  hasClose={false}
                  hasCancel={true}
                  confirmLabel="Login"
                  onConfirm={(close) => {
                    navigate("/sign-in");
                  }}
                  shouldCloseOnOverlayClick={false}
                  shouldCloseOnEscapePress={false}
                >
                  {notLoggedInErrorMessage}
                </Dialog>
              </Pane>
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-white pt-5 mt-5 pb-5">
          <div className="container rs-page">
            <div className="row">
              <div className="col-md-12 d-flex justify-content-center">
                <h4 className="float-start pt-4 ">
                  Order #{" "}
                  {orderDetail?.data[0] !== undefined
                    ? orderDetail?.data[0]?.order_id
                    : ""}
                </h4>
              </div>
            </div>
            <div className="row">
              <div className="card" style={{ borderColor: "white" }}>
                <div className="card-body">
                  <div className="row">
                    <div className="col-2">Order Id</div>
                    <div className="col-10">Order Id</div>
                  </div>
                  <div className="row">
                    <div className="col-2">Order Id</div>
                    <div className="col-10">Order Id</div>
                  </div>
                  <div className="row">
                    <p className="col-sm-5 font-weight-bold">Order Id</p>
                    <p className="col-sm-7">
                      {orderDetail?.data[0] !== undefined
                        ? orderDetail?.data[0]?.order_id
                        : ""}
                    </p>
                  </div>
                  <div className="row">
                    <p className="col-sm-5 font-weight-bold">Order Status</p>
                    <p className="col-sm-7">
                      {orderDetail?.data[0]?.order_status}
                    </p>
                  </div>
                  <div className="row">
                    <p className="col-sm-5 font-weight-bold">Total Amount</p>
                    <p className="col-sm-7">
                      {orderDetail?.data[0]?.total_amount}
                    </p>
                  </div>
                  <div className="row">
                    <p className="col-sm-5 font-weight-bold">Address</p>
                    <div className="col-sm-7 ">
                      <p>
                        {orderDetail?.data[0]?.location?.house},{" "}
                        {orderDetail?.data[0]?.location?.area},{" "}
                        {orderDetail?.data[0]?.location?.locality},{" "}
                        {orderDetail?.data[0]?.location?.city},{" "}
                        {orderDetail?.data[0]?.location?.pincode}{" "}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <p className="col-sm-5 font-weight-bold">
                      Additional Comments
                    </p>
                    <p className="col-sm-7">
                      {orderDetail?.data[0]?.additional_comments}
                    </p>
                  </div>
                  <div className="row">
                    <p className="col-sm-5 font-weight-bold">Created At</p>
                    <p className="col-sm-7">
                      {moment(orderDetail?.data[0]?.createdAt).format(
                        "DD MMMM YYYY"
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="card" style={{ borderColor: "white" }}>
                <div className="card-header">Shop Details</div>
                <div className="card-body">
                  <div className="row">
                    <p className="col-sm-5 font-weight-bold">Shop Name</p>
                    <p className="col-sm-7">
                      {orderDetail?.data[0]?.shop_id?.name}
                    </p>
                  </div>
                  <div className="row">
                    <p className="col-sm-5 font-weight-bold">Mobile</p>
                    <p className="col-sm-7">
                      {orderDetail?.data[0]?.shop_id?.mobile}
                    </p>
                  </div>
                  <div className="row">
                    <p className="col-sm-5 font-weight-bold">Email</p>
                    <p className="col-sm-7">
                      {orderDetail?.data[0]?.shop_id?.email}
                    </p>
                  </div>
                  <div className="row">
                    <p className="col-sm-5 font-weight-bold">Address</p>
                    <div className="col-sm-7 ">
                      <p>
                        {orderDetail?.data[0]?.shop_id?.address?.house},{" "}
                        {orderDetail?.data[0]?.shop_id?.address?.area},{" "}
                        {orderDetail?.data[0]?.shop_id?.address?.locality},{" "}
                        {orderDetail?.data[0]?.shop_id?.address?.city},{" "}
                        {orderDetail?.data[0]?.shop_id?.address?.pincode}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="card" style={{ borderColor: "white" }}>
                <div className="card-header">Transaction Details</div>
                <div className="card-body">
                  <div className="row">
                    <p className="col-sm-5 font-weight-bold">
                      Transaction Type
                    </p>
                    <p className="col-sm-7">
                      {String(
                        orderDetail?.data[0]?.transaction_details
                          ?.transaction_type
                      )}
                    </p>
                  </div>
                  <div className="row">
                    <p className="col-sm-5 font-weight-bold">
                      Transaction Amount
                    </p>
                    <p className="col-sm-7">
                      {orderDetail?.data[0]?.transaction_details?.amount}
                    </p>
                  </div>
                  <div className="row">
                    <p className="col-sm-5 font-weight-bold">Status</p>
                    <p className="col-sm-7">
                      {
                        orderDetail?.data[0]?.transaction_details
                          ?.transaction_status
                      }
                    </p>
                  </div>
                </div>
              </div>
              {orderDetail.data[0].order_items.length === 0 ? (
                <h3 className="fw-bold">No Details Found</h3>
              ) : (
                ""
              )}
            </div>

            <div className="card" style={{ borderColor: "white" }}>
              <div className="card-header">Order Items</div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ width: "1px" }}>Item Code</th>
                        <th>Product Name</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Final price</th>
                        <th>Product Description</th>
                        <th>Vegetarian</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderDetail?.data[0]?.order_items.map((order, key) => {
                        return (
                          <tr key={key}>
                            <td>{order?.item_code}</td>
                            <td>{order?.product_name}</td>
                            <td>{order?.price}</td>
                            <td>{order?.quantity}</td>
                            <td>{order?.final_price}</td>
                            <td>{order?.product_description}</td>
                            <td>{String(order?.vegetarian)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {orderDetail?.data[0]?.order_status !== "Delivered" &&
              orderDetail?.data[0]?.order_status !== "Rejected" ? (
              <div className="row justify-content-center">
                <Button
                  marginY={8}
                  marginRight={12}
                  height={48}
                  iconBefore={MapIcon}
                  appearance="primary"
                  intent="success"
                  onClick={() => {
                    navigate(`/tracking/${orderDetail?.data[0]?.order_id}`);
                  }}
                >
                  Track Order
                </Button>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default OrderDetail;
