import React, { useEffect, useState } from "react";
import rider from "../images/rider.png";
import place from "../images/place.png";
import { selectTrackOrder, getTrackOrder } from "../features/order/orderSlice";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Dialog, Pane, Spinner } from "evergreen-ui";
import {
  notLoggedInErrorMessage,
  notLoggedInErrorTitle,
} from "../services/standard";
import TrackOrderGoogleMap from "../components/TrackOrderGoogleMap";

const Tracking = () => {
  const navigate = useNavigate();
  const selectOrderData = useSelector(selectTrackOrder);
  const selectOrder = selectOrderData?.result;
  const [isLoading, setLoading] = useState(true);
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    setLoading(true);
    dispatch(getTrackOrder({ orderId: params.id }));
  }, [dispatch, params.id]);

  useEffect(() => {
    if (selectOrderData?.status === "complete") {
      setLoading(false);
    }
  }, [selectOrderData?.status]);

  return (
    <div>
      {isLoading ? (
        <div className="bg-white pt-5 mt-5 pb-5 page-min-height">
          <div className="container rs-page">
            <div className="row">
              <Pane>
                <Spinner marginX="auto" marginY={120} />
              </Pane>
            </div>
          </div>
        </div>
      ) : selectOrder?.reauth ? (
        <div className="bg-white pt-5 mt-5 pb-5 page-min-height">
          <div className="container rs-page">
            <div className="row">
              <Pane>
                <Dialog
                  isShown={true}
                  title={notLoggedInErrorTitle}
                  intent="success"
                  hasClose={false}
                  hasCancel={true}
                  confirmLabel="Login"
                  onConfirm={(close) => {
                    navigate("/sign-in");
                  }}
                  shouldCloseOnOverlayClick={false}
                  shouldCloseOnEscapePress={false}
                >
                  {notLoggedInErrorMessage}
                </Dialog>
              </Pane>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid bg-white pt-5 mt-5 pb-5 page-min-height">
          <div className="row gutter-0">
            <div className="col-md-7 col-sm-12">
              <h5>ORDER ID# {params.id}</h5>
            </div>
          </div>
          <div className="row gutter-0 ">
            <div
              className="col-lg-6 col-sm-12 edr-bg-gray edr-signup-form-radius mt-5"
              style={{ minHeight: "50vh" }}
            >
              <TrackOrderGoogleMap
                origin={{
                  lat: selectOrder?.data[0]?.Shop[0]?.address?.latitude,
                  lng: selectOrder?.data[0]?.Shop[0]?.address?.longitude,
                }}
                destination={{
                  lat: selectOrder?.data[0]?.location?.latitude,
                  lng: selectOrder?.data[0]?.location?.longitude,
                }}
                googleMapsApiKey={googleMapsApiKey}
                shopName={selectOrder?.data[0]?.Shop[0]?.name}
              />
            </div>
            <div
              className="col-lg-6 col-sm-12 secondary-bg-color edr-dft-image-radius address-gray mt-5"
              style={{
                paddingLeft: "0px",
                paddingRight: "0px",
              }}
            >
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div
                      className="card-body address-gray"
                      style={{ minHeight: "50vh" }}
                    >
                      <div className="row mx-3">
                        <div className="col-lg-6">
                          <h6 className="fw-bold float-start">
                            Pickup Details
                          </h6>
                        </div>
                        <div className="col-lg-6"></div>
                      </div>
                      <div className="card edr-card-radius mt-3 mx-3">
                        <div className="card-body">
                          <div className="row">
                            <ol>
                              {selectOrder.data[0] !== undefined
                                ? selectOrder.data[0].order_items.map(
                                  (s, key) => {
                                    return (
                                      <li key={key} className="ms-3">
                                        <div className="row">
                                          <div className="col-md-9 col-lg-9 col-sm-8 col-7 text-start">
                                            {s?.product_name} x {s?.quantity}
                                            <p>{s?.product_description}</p>
                                            <p>{s?.comments}</p>
                                          </div>
                                          <div className="col-md-3 col-lg-3 col-sm-4 col-5 text-end">
                                            AED {s?.final_price}
                                          </div>
                                        </div>
                                      </li>
                                    );
                                  }
                                )
                                : ""}
                            </ol>
                          </div>
                        </div>
                      </div>
                      <div className="row mx-3 mt-3">
                        <div className="col-md-9 float-start">
                          {/* <h6>Estimated time</h6> */}
                        </div>
                        <div className="col-md-3 float-end">
                          {/* <h6>30 min</h6> */}
                        </div>
                      </div>
                      <div className="row mx-3 mt-2">
                        <div className="col-md-12">
                          {/* <div className="progress">
                                  <div
                                    className="progress-bar progress-bar"
                                    role="progressbar"
                                    style={{ width: "25%" }}
                                    aria-valuenow={25}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                  />
                                </div> */}
                          <div className="mt-3">
                            <h6 className="text-edr-secondary">
                              {selectOrder.data[0] !== undefined &&
                                selectOrder.data[0].order_status === "New"
                                ? "Waiting for your Order to get Accepted"
                                : selectOrder.data[0] !== undefined &&
                                  selectOrder.data[0].order_status ===
                                  "Accepted"
                                  ? "Your Order is Accepted"
                                  : selectOrder.data[0] !== undefined &&
                                    selectOrder.data[0].order_status ===
                                    "Rejected"
                                    ? "Your Order is Rejected"
                                    : selectOrder.data[0] !== undefined &&
                                      selectOrder.data[0].order_status ===
                                      "Prepared"
                                      ? "Preparing your Order"
                                      : selectOrder.data[0] !== undefined &&
                                        selectOrder.data[0].order_status ===
                                        "Assigned"
                                        ? "Order is Picked"
                                        : selectOrder.data[0] !== undefined &&
                                          selectOrder.data[0].order_status ===
                                          "OutForDelivery"
                                          ? "Order is Out For Delivery"
                                          : selectOrder.data[0] !== undefined &&
                                            selectOrder.data[0].order_status ===
                                            "Delivered"
                                            ? "Order is Delivered"
                                            : ""}
                            </h6>
                          </div>
                        </div>
                      </div>
                      {selectOrder.data[0] !== undefined &&
                        selectOrder.data[0].deliveryboy.length > 0 ? (
                        <div className="card edr-card-radius mt-3 mx-3">
                          <div className>
                            <div className="row">
                              <div className="col-md-3">
                                <img
                                  alt=""
                                  className="image-fluid"
                                  src={rider}
                                />
                              </div>
                              <div className="col-md-3 mt-3 ms-3">
                                <h6 className="float-start">
                                  {selectOrder.data[0].deliveryboy[0].name}
                                </h6>
                              </div>
                              <div className="col-md-5 mt-2 "></div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {selectOrder?.data[0] !== undefined ? (
                        <div className="row ms-3 mt-3">
                          <div>
                            <h6>Delivery Address</h6>
                          </div>
                          <div className="col-1">
                            <img alt="" src={place} />
                          </div>
                          <div className="col-11">
                            {selectOrder?.data[0]?.location?.house},{" "}
                            {selectOrder?.data[0]?.location?.area}
                            <br />
                            {selectOrder?.data[0]?.location?.city},{" "}
                            {selectOrder?.data[0]?.location?.locality},{" "}
                            {selectOrder?.data[0]?.location?.pincode}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {/* cancel order feature to be added later */}
                      <div className="row px-5 py-5 center">
                        {/* <button
                                className="btn btn-md secondary-bg-color btn-explore text-white fw-bold center"
                                id="cancelOrder"
                              >
                                CANCEL ORDER{" "}
                              </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Tracking;
