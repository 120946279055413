import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiRunner } from "../../services/communicator";
import {
  getItem,
  postPaymentStatusUpdate,
  removeItem,
  runningOrderDetails,
} from "../../services/standard";

export const postPaymentSuccess = createAsyncThunk(
  "paymentSuccess/postPaymentStatusUpdate",
  async (obj) => {
    const requestOptions = Object.assign({}, postPaymentStatusUpdate);
    requestOptions.url = `${requestOptions.url}/${obj.id}?type=${obj.type}`;
    const response = await ApiRunner(requestOptions);
    return response;
  }
);

export const postPaymentFailed = createAsyncThunk(
  "paymentFailed/postPaymentStatusUpdate",
  async (obj) => {
    const requestOptions = Object.assign({}, postPaymentStatusUpdate);
    requestOptions.url += obj.id;
    const response = await ApiRunner(requestOptions);
    return response;
  }
);

export const paymentStatusSlice = createSlice({
  name: "paymentSuccess ,paymentFailed",
  initialState: {
    status: "idle",
    success: {},
    failed: {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(postPaymentSuccess.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postPaymentSuccess.fulfilled, (state, action) => {
        state.status = "complete";
        const referenceIdFromStorage = getItem(runningOrderDetails);
        if (
          referenceIdFromStorage ===
          action.payload?.data?.transaction_details?.transaction_id
        ) {
          removeItem(runningOrderDetails);
        }
        state.paymentSuccess = action.payload;
      })
      .addCase(postPaymentFailed.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postPaymentFailed.fulfilled, (state, action) => {
        state.status = "complete";
        const referenceIdFromStorage = getItem(runningOrderDetails);
        if (referenceIdFromStorage) {
          removeItem(runningOrderDetails);
        }
        state.failed = action.payload;
      });
  },
});

export const paymentSuccessful = (state) => state.paymentSuccess;
export const paymentFailed = (state) => state.paymentFailed;
export default paymentStatusSlice.reducer;
