import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiRunner } from "../../services/communicator";
import {
  deleteCartItem,
  getCartJson,
  postPlaceOrder,
  runningOrderDetails,
  setItem,
} from "../../services/standard";
import { toasterType, toasterService } from "../../services/toasterService";

export const getCart = createAsyncThunk("cart/getCart", async (obj) => {
  const requestOptions = Object.assign({}, getCartJson);
  const response = await ApiRunner(requestOptions);
  return response;
});

export const placeOrder = createAsyncThunk(
  "placeOrder/postPlaceOrder",
  async (obj) => {
    const PostPlaceOrder = Object.assign({}, postPlaceOrder);
    PostPlaceOrder[`body`] = JSON.stringify(obj);
    const response = await ApiRunner(PostPlaceOrder);
    toasterService(
      response.status === true ? toasterType.success : toasterType.danger,
      response?.message
    );
    return response;
  }
);

export const removeCartItem = createAsyncThunk(
  "removeCartItem/deleteCartItem",
  async (obj) => {
    const DeleteCartItem = Object.assign({}, deleteCartItem);
    DeleteCartItem.url = `${DeleteCartItem.url}/${obj.cartId}/${obj.productId}`;
    const response = await ApiRunner(DeleteCartItem);
    toasterService(
      toasterType.success,
      "The item has been successfully removed from your shopping cart "
    );
    return response;
  }
);

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    status: "idle",
    popupState: false,
    result: { data: {} },
  },

  reducers: {
    setIsPopupModal: (state, action) => {
      state.popupState = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getCart.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCart.fulfilled, (state, action) => {
        state.status = "complete";
        state.result = action.payload;
      })
      .addCase(placeOrder.pending, (state) => {
        state.status = "loading";
      })
      .addCase(placeOrder.fulfilled, (state, action) => {
        state.status = "complete";
        if (
          action?.payload &&
          action?.payload?.pgData?._links?.payment?.href &&
          action?.payload?.data?.transaction_details?.transaction_type ===
            "Online-Ngenius"
        ) {
          const runningOrderJson = {
            runningOrderID: action.payload?.pgData?.reference,
            runningOrderType: "Ngenius",
          };
          if (action.payload?.pgData?.reference) {
            setItem(runningOrderDetails, JSON.stringify(runningOrderJson));
          }
          if (action.payload?.pgData?._links.payment?.href) {
            window.location.href = action.payload?.pgData?._links.payment.href;
          } else {
            toasterService(
              toasterType.danger,
              "Your order could not be placed at the moment. Please try again later."
            );
          }
        } else if (
          action?.payload &&
          action?.payload?.data?.transaction_details?.transaction_type ===
            "Online-Cod"
        ) {
          window.location.href = `/paymentsuccess?ref=${action?.payload?.data?.order_id}&type=cod`;
        } else {
          toasterService(
            toasterType.danger,
            "Your order could not be placed at the moment. Please try again later."
          );
        }
      });
  },
});
export const selectCart = (state) => state.cart;
export const popupModalStateChange = (state) => state?.cart?.popupState;

export const { setIsPopupModal } = cartSlice.actions;
export default cartSlice.reducer;
