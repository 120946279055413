import React, { useEffect, useRef, useState } from "react";
import Lottie from "lottie-react";
import vaas from "../lottie/vaas-delivery.json";
import iph from "../images/iph.png";
import playStore from "../images/logo_playstore.svg";
import appStore from "../images/logo_appstore.svg";
import appGallery from "../images/logo_huaweistore.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getnearbyShops,
  selectNearbyShops,
  setGooglePlaceTriggered,
  getnearbyShopsGuest,
  setLatLong,
} from "../features/restaurant/homeSlice";
import { IconButton, LocateIcon, Pane, Spinner, Tooltip } from "evergreen-ui";
import {
  selectedPrimaryAddressLat,
  selectedPrimaryAddressLong,
} from "../features/restaurant/addressSlice";
import {
  getToken,
  setLat,
  setLong,
  getLat,
  getLong,
  removePopupState,
  removeLong,
  removeLat,
  setLocationName,
  removeLocationName,
} from "../services/standard";
import NearByComponent from "../components/NearByComponent";
import { toasterService, toasterType } from "../services/toasterService";
import { statusIsGoogleMapsLoaded } from "../features/restaurant/headerSlice";
import { Autocomplete } from "@react-google-maps/api";

const Home = () => {
  const horizontalCategoryBarReference = useRef();
  const nearByComponentReference = useRef();
  const [locateMe, setLocateMe] = useState(true);
  const selectedNearbyShopsData = useSelector(selectNearbyShops);

  const selectedNearbyShops = selectedNearbyShopsData?.nearbyShops;
  const selectedLat = selectedNearbyShopsData?.lat;
  const selectedLng = selectedNearbyShopsData?.long;
  const isGooglePlaceTriggered = selectedNearbyShopsData?.googlePlaceTriggered;
  const [isLoading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const token = getToken();
  const [autocomplete, setAutocomplete] = useState();
  const primaryaddressLat = useSelector(selectedPrimaryAddressLat);
  const primaryaddressLong = useSelector(selectedPrimaryAddressLong);
  const [navigatorPermission, setNavigatorPermission] = useState("");
  const [locationValue, setLocationValue] = useState("");
  const [isGooglePlaceDataAvailable, setGooglePlaceDataAvailable] =
    useState(false);
  useEffect(() => {
    removePopupState();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    function showPosition(position) {
      dispatch(
        getnearbyShopsGuest({
          lat: position?.coords?.latitude,
          long: position?.coords?.longitude,
        })
      );
    }

    const noLocationPermission = () => {
      toasterService(
        toasterType.danger,
        "Please enable the permission to fetch location"
      );

      setLoading(false);
    };
    async function geoLocator() {
      setLoading(false);

      if (token && primaryaddressLat && primaryaddressLong) {
        dispatch(
          getnearbyShops({
            lat: primaryaddressLat,
            long: primaryaddressLong,
          })
        );
      } else {
        if (getLat() && getLong()) {
          dispatch(
            getnearbyShopsGuest({
              lat: getLat(),
              long: getLong(),
            })
          );
        } else {
          const permission = await navigator?.permissions?.query({
            name: "geolocation",
          });
          setNavigatorPermission(permission?.state);

          if (locateMe) {
            if (permission?.state !== "denied") {
              navigator?.geolocation?.getCurrentPosition(
                showPosition,
                (positionError) => {
                  if (positionError) {
                    toasterService(
                      toasterType.danger,
                      positionError?.message +
                        ". Please manually allow location in your browser."
                    );
                  }
                },
                { enableHighAccuracy: true }
              );
            } else {
              noLocationPermission();
            }
          } else {
            dispatch(getnearbyShopsGuest({ lat: "25.2048", long: "55.2708" }));
          }
        }
      }
    }
    geoLocator();
  }, [locateMe, token, primaryaddressLat, primaryaddressLong]);

  const findRestaurants = () => {
    if (isGooglePlaceTriggered && selectedLat && selectedLng) {
      dispatch(setGooglePlaceTriggered(false));
      setGooglePlaceDataAvailable(true);
      dispatch(
        getnearbyShopsGuest({
          lat: selectedLat,
          long: selectedLng,
        })
      );
      setLat(selectedLat);
      setLong(selectedLng);
      setLocationName(locationValue);
      window.location.reload(false);
    } else {
      toasterService(
        toasterType.warning,
        "Please select your location and press find restaurant"
      );
    }
  };

  useEffect(() => {
    if (selectedNearbyShopsData?.status === "complete") {
      setLoading(false);
      if (
        nearByComponentReference &&
        nearByComponentReference?.current?.offsetTop
      ) {
        window.scrollTo(0, nearByComponentReference?.current?.offsetTop);
      }
    }
  }, [selectedNearbyShopsData?.status]);

  function handleCallback(childData) {
    const lat = childData?.geometry?.location?.lat();
    const lng = childData?.geometry?.location?.lng();

    if (lat && lng) {
      dispatch(
        setLatLong(
          JSON.stringify({
            lat: lat,
            lng: lng,
          })
        )
      );
      dispatch(setGooglePlaceTriggered(true));
    } else {
      toasterService(
        toasterType.warning,
        "Please select your location and press find restaurant"
      );
    }
  }

  let left = 0;
  const toMoveScroll = 340;
  const previousHorizontalCategoryBar = () => {
    if (left > 0) {
      left = left - toMoveScroll;
      horizontalCategoryBarReference.current.scrollTo({
        left: left,
        behavior: "smooth",
      });
    }
  };

  const nextHorizontalCategoryBar = () => {
    const divMaxScrollWidth =
      horizontalCategoryBarReference.current.scrollWidth -
      horizontalCategoryBarReference.current.offsetWidth;
    if (left <= divMaxScrollWidth) {
      left = left + toMoveScroll;
      horizontalCategoryBarReference.current.scrollTo({
        left: left,
        behavior: "smooth",
      });
    }
  };

  const isLoaded = useSelector(statusIsGoogleMapsLoaded);
  const onLoad = (autocompleteParam) => {
    setAutocomplete(autocompleteParam);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const autocompleteData = autocomplete.getPlace();
      setLocationValue(autocompleteData?.formatted_address);
      handleCallback(autocompleteData);
    } else {
    }
  };

  function locateMyDirection() {
    removeLat();
    removeLong();
    removeLocationName();
    setLocateMe(true);
  }

  return (
    <div>
      {isLoading ? (
        <div className="bg-white">
          <div className="container pt-5 rs-page">
            <div className="row">
              <Pane>
                <Spinner marginX="auto" marginY={120} />
              </Pane>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="secondary-bg-color mb-5 mt-5">
            <div className="container-fluid d-flex justify-content-center vertical-center pt-5">
              <div className="row">
                <div className="col-md-6 d-flex justify-content-center vertical-center">
                  <div className="float-left">
                    <h1 className="text-edr-yellow fw-bold" id="textEdit">
                      Good Food For You!
                    </h1>
                    <p
                      className="text-white"
                      style={{ fontSize: "22px" }}
                      id="textEdit1"
                    >
                      Order food from favourite Eat and Drink restaurants near
                      you
                    </p>
                    {!token ? (
                      <>
                        <form>
                          <div className="buttonIn">
                            {isLoaded ? (
                              <>
                                <Autocomplete
                                  onLoad={onLoad}
                                  onPlaceChanged={onPlaceChanged}
                                  restrictions={{ country: "ae" }}
                                >
                                  <input
                                    type="text"
                                    className="searchbox"
                                    placeholder="Search places"
                                  />
                                </Autocomplete>
                                <span
                                  className="btn bg-edr-yellow text-white search-btn fw-bold homeSearchBtn"
                                  onClick={findRestaurants}
                                >
                                  FIND RESTAURANTS
                                </span>
                                {selectedNearbyShopsData?.googlePlaceAddress
                                  ?.length === 0 ? (
                                  <span
                                    onClick={() => {
                                      locateMyDirection();
                                    }}
                                    className="cursor-pointer locateMeButton"
                                  >
                                    <Tooltip content="Locate Me">
                                      <IconButton
                                        icon={LocateIcon}
                                        className="locateMe"
                                        size="large"
                                      />
                                    </Tooltip>
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </form>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="col-md-6 d-flex justify-content-center vertical-center pt-4">
                  <div className="float-right">
                    <Lottie
                      animationData={vaas}
                      style={{
                        maxWidth: 486,
                        backgroundColor: "#93E3C1",
                        borderRadius: 100,
                      }}
                      autoPlay={true}
                      loop="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div ref={nearByComponentReference}>
            {token && primaryaddressLat && primaryaddressLong ? (
              <NearByComponent
                previousHorizontalCategoryBar={previousHorizontalCategoryBar}
                horizontalCategoryBarReference={horizontalCategoryBarReference}
                selectedNearbyShops={selectedNearbyShops}
                nextHorizontalCategoryBar={nextHorizontalCategoryBar}
              />
            ) : navigatorPermission !== "denied" ? (
              <NearByComponent
                previousHorizontalCategoryBar={previousHorizontalCategoryBar}
                horizontalCategoryBarReference={horizontalCategoryBarReference}
                selectedNearbyShops={selectedNearbyShops}
                nextHorizontalCategoryBar={nextHorizontalCategoryBar}
              />
            ) : isGooglePlaceDataAvailable ? (
              <NearByComponent
                previousHorizontalCategoryBar={previousHorizontalCategoryBar}
                horizontalCategoryBarReference={horizontalCategoryBarReference}
                selectedNearbyShops={selectedNearbyShops}
                nextHorizontalCategoryBar={nextHorizontalCategoryBar}
              />
            ) : (
              <></>
            )}
          </div>
          {/*todo: Uncomment when needed in future */}
          {/* <div className="bg-edr-light-green pb-5" id="length">
              <div className="container">
                <div className="row">
                  <div className="col-md-6 mb-5">
                    <h1 className="mt-5 fw-bold mb-4">We can serve it better</h1>
                    <p className="mb-4">
                      Amet minim mollit non deserunt ullamco est sit aliqua dolor
                      <br /> do amet sint. Velit officia consequat duis enim velit
                      mollit.
                      <br /> Exercitation veniam consequat sunt nostrud amet.
                    </p>
                    <button className="btn btn-md secondary-bg-color btn-explore text-white fw-bold">
                      EXPLORE
                    </button>
                  </div>
                  <div className="col-md-6">
                    <img
                      alt=""
                      className="edr-vaas-tag img-fluid"
                      id="Card2"
                      src={vaas2}
                    />
                  </div>
                </div>
              </div>
            </div> */}
          {/*todo: uncomment till here */}
          <div className="edr-bg-yellow">
            <div className="container">
              <div className="row">
                <div className="col-md-6 vertical-center">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                        <h1 className="text-white pt-5 fw-bold" id="textEdit3">
                          Order From Our App In <br />
                          Three Steps
                        </h1>
                      </div>
                    </div>
                    <div className="row mt-5">
                      <div className="col-sm-12 col-xl-4 d-flex justify-content-center flex-wrap p-1">
                        <a
                          className="app-link"
                          href="https://play.google.com/store/apps/details?id=com.eatanddrink"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={playStore}
                            className="img-fluid"
                            alt="Play Store EDR"
                          />
                        </a>
                      </div>
                      <div className="col-sm-12 col-xl-4 d-flex justify-content-center flex-wrap p-1 ">
                        <a
                          className="app-link"
                          href="https://apps.apple.com/us/app/eat-and-drink/id1636146512"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={appStore}
                            className="img-fluid"
                            alt="App Store EDR"
                          />
                        </a>
                      </div>
                      <div className="col-sm-12 col-xl-4 d-flex justify-content-center flex-wrap p-1">
                        <a
                          className="app-link"
                          href="https://appgallery.huawei.com/app/C106251543"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={appGallery}
                            className="img-fluid"
                            alt="App Gallery EDR"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 vertical-center">
                  <img
                    alt=""
                    className="pattern-bg app-download-img center img-fluid"
                    id="card4"
                    src={iph}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Home;
