import React, { useEffect, useState } from "react";
import {
  addCart,
  getGuestShopByProduct,
  getShopByProduct,
} from "../features/restaurant/restaurantSlice";
import { Badge, Pane, Spinner } from "evergreen-ui";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategoryDetail,
  getCategoryInfoDetail,
} from "../features/restaurant/CategoryViewSlice";
import {
  getGuestSearchProduct,
  getProductById,
  getProductDetailsGuestJson,
  getSearchProduct,
} from "../features/restaurant/searchSlice";
import { getToken } from "../services/standard";
import { getCartCountASyncThunk } from "../features/restaurant/headerSlice";
import { getCart, setIsPopupModal } from "../features/restaurant/cartSlice";

const QuantitySelectorCard = (props) => {
  const [quantity, setQuantity] = useState(props?.cartQuantity);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const token = getToken();
  const [loginDialog, setLoginDialog] = useState(false);
  const statePopUp = useSelector((state) => state?.cart?.popupState);

  async function addToCart(event, quantityInCart) {
    event.preventDefault();
    event.stopPropagation();
    if (!token) {
      dispatch(setIsPopupModal(!statePopUp));
      return false;
    } else {
      setLoading(true);
      if (quantityInCart < 0) {
        setQuantity(0);
        return;
      }
      await dispatch(
        addCart({
          product_id: props?.productId,
          quantity: quantityInCart,
          shop_id: props?.shopId,
        })
      );

      if (token) {
        if (props?.shopId && props?.restaurantPageNumber) {
          await dispatch(
            getShopByProduct({
              shopId: props?.shopId,
              pageno: props?.restaurantPageNumber,
            })
          );
        }

        if (props?.catId && props?.categoryPageNumber) {
          console.log("cat loop");
          await dispatch(
            getCategoryInfoDetail({
              catId: props?.catId,
              pageNo: props?.categoryPageNumber,
            })
          );
        }

        if (props?.productId) {
          console.log("product loop");
          dispatch(getProductById({ productId: props?.productId }));
        }

        if (props?.lat && props?.long && props?.paramValue) {
          console.log("search loop");
          dispatch(
            getSearchProduct({
              lat: props?.lat,
              long: props?.long,
              name: props?.paramValue,
              pageno: props?.searchPageNumber,
            })
          );
        }

        await dispatch(getCart());
        await dispatch(getCartCountASyncThunk());
      } else {
        console.log("token no loop, will this ever work?");
        if (props?.catId) {
          await dispatch(
            getCategoryDetail({
              catId: props?.catId,
              pageNo: props?.categoryPageNumber,
            })
          );
        }
        if (
          props?.lat &&
          props?.long &&
          props?.paramValue &&
          props?.searchPageNumber
        ) {
          dispatch(
            getGuestSearchProduct({
              lat: props?.lat,
              long: props?.long,
              name: props?.paramValue,
              pageno: props?.searchPageNumber,
            })
          );
        }

        if (props?.productId) {
          dispatch(getProductDetailsGuestJson({ productId: props?.productId }));
        }

        if (props?.shopId && props?.restaurantPageNumber) {
          dispatch(
            getGuestShopByProduct({
              shopId: props?.shopId,
              pageno: props?.restaurantPageNumber,
            })
          );
        }

        await dispatch(getCartCountASyncThunk());
      }
      setLoading(false);
    }
  }
  useEffect(() => {
    setQuantity(props?.cartQuantity);
  }, [props?.cartQuantity]);

  return (
    <div>
      {loginDialog ? <> </> : <></>}
      {props?.outOfStock ? (
        <Badge
          color="#ff8c00"
          style={{ height: "25px", display: "table-cell" }}
        >
          Out of Stock
        </Badge>
      ) : props?.cartQuantity === 0 ? (
        isLoading ? (
          <Pane>
            <Spinner marginX="auto" />
          </Pane>
        ) : (
          <button
            className={
              props?.isOpen
                ? "btn btn-sm secondary-bg-color text-white edr-dft-add-btn"
                : "btn btn-sm text-white bg-edr-grayout-non-click"
            }
            style={{
              borderRadius: "14px",
              width: "100%",
              minWidth: "55px",
              zIndex: "999",
            }}
            onClick={(event) => {
              setQuantity(1);
              addToCart(event, 1);
            }}
          >
            {props?.isOpen ? "ADD" : "Shop Closed"}
          </button>
        )
      ) : (
        <div className="quantity-input">
          {isLoading ? (
            <Pane>
              <Spinner marginX="auto" />
            </Pane>
          ) : (
            <>
              {props?.isOpen ? (
                <>
                  {" "}
                  <button
                    className="quantity-input__modifier quantity-input__modifier--left "
                    onClick={(event) => {
                      if (token) {
                        setQuantity(quantity - 1);
                        addToCart(event, quantity - 1);
                      } else {
                        setLoginDialog(true);
                      }
                    }}
                  >
                    -
                  </button>
                  <input
                    className="quantity-input__screen"
                    type="text"
                    value={quantity ? quantity : 0}
                    readOnly
                  />
                  <button
                    className="quantity-input__modifier quantity-input__modifier--right"
                    onClick={(event) => {
                      setQuantity(quantity + 1);
                      addToCart(event, quantity + 1);
                    }}
                  >
                    +
                  </button>{" "}
                </>
              ) : (
                <div>
                  <button
                    className={
                      props?.isOpen
                        ? "btn btn-sm  secondary-bg-color text-white edr-dft-add-btn"
                        : "btn btn-sm  text-white  bg-edr-grayout-non-click"
                    }
                    style={{
                      borderRadius: "14px",
                      width: "100%",
                      minWidth: "55px",
                      zIndex: "999",
                    }}
                  >
                    Shop Closed
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};
export default QuantitySelectorCard;
