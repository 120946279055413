import moment from "moment";

// all request options goes here
export const signInRequestOptions = {
  method: "POST",
  headers: {
    accept: `application/json`,
    "Content-Type": `application/json`,
  },
  url: "v1/users/login",
};

export const signInV2RequestOptions = {
  method: "POST",
  headers: {
    accept: `application/json`,
    "Content-Type": `application/json`,
  },
  url: "v2/users/login",
};

export const signUpRequestOptions = {
  method: "POST",
  headers: {
    accept: `application/json`,
    "Content-Type": `application/json`,
  },
  url: "v1/users/signup",
};

export const signUpV2RequestOptions = {
  method: "POST",
  headers: {
    accept: `application/json`,
    "Content-Type": `application/json`,
  },
  url: "v2/users/signup",
};

export const homeRequestOptions = {
  method: "get",
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
  url: "v1/users/home/",
  authenticate: true,
};

export const homeGuestRequestOptions = {
  method: "get",
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
  url: "v1/users/home-guest/",
};

export const profileRequestOptions = {
  method: "get",
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
  url: "v1/users/profile/",
  authenticate: true,
};

export const editProfileRequestOptions = {
  method: "put",
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
  url: "v1/users/profile/",
  authenticate: true,
};

export const postRatingJson = {
  method: "post",
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
  url: "v1/users/shop-rating",
  authenticate: true,
};

export const restaurantProductRequestOptions = {
  method: "get",
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
  url: "v1/users/shop",
  authenticate: true,
};

export const restaurantProductWithPagenationRequestOptions = {
  method: "get",
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
  url: "v2/users/shop",
  authenticate: true,
};

export const restaurantListWithPagenationOptions = {
  method: "get",
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
  url: "v2/users/see-all-shops",
  authenticate: true,
};

export const restaurentGuestProductRequestOptions = {
  method: "get",
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
  url: "v1/users/guest-shop",
  authenticate: true,
};

export const restaurentGuestProductWithPgenationRequestOptions = {
  method: "get",
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
  url: "v2/users/guest-shop",
  authenticate: true,
};

export const getCartJson = {
  method: "get",
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
  authenticate: true,
  url: "v1/users/get-cart",
};

export const getSearchProductJson = {
  method: "get",
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
  authenticate: true,
  url: "v2/users/user-search",
};

export const getProductDetailsJson = {
  method: "get",
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
  authenticate: true,
  url: "v1/users/products",
};

export const getGuestProductDetailsByIdJson = {
  method: "get",
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
  url: "v1/users/guest-products",
};

export const getGuestProductDetailsJson = {
  method: "get",
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
  url: "v1/users/guest-products",
};

export const getGuestSearchProductJson = {
  method: "get",
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
  authenticate: true,
  url: "v2/users/search-product",
};

export const getPrimaryAddressJson = {
  method: "get",
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
  authenticate: true,
  url: "v2/users/primary-address",
};

export const getAddressByIdJson = {
  method: "get",
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
  authenticate: true,
  url: "v2/users/address",
};

export const postPrimaryAddressJson = {
  method: "put",
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
  authenticate: true,
  url: "v2/users/primary-address",
};

export const deleteAddressJson = {
  method: "delete",
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
  authenticate: true,
  url: "v2/users/address",
};

export const getAllAddressJson = {
  method: "get",
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
  authenticate: true,
  url: "v2/users/address",
};

export const updateAddress = {
  method: "put",
  headers: {
    accept: `application/json`,
    "Content-Type": `application/json`,
  },
  authenticate: true,
  url: "v2/users/address",
};

export const postAddress = {
  method: "post",
  headers: {
    accept: `application/json`,
    "Content-Type": `application/json`,
  },
  authenticate: true,
  url: "v2/users/address",
};

export const getTrackOrderJson = {
  method: "get",
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
  authenticate: true,
  url: "v1/users/track-order",
};

export const getOrderJson = {
  method: "get",
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
  authenticate: true,
  url: "v1/users/orders",
};

export const getOrderJsonv2 = {
  method: "get",
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
  authenticate: true,
  url: "v2/users/orders",
};

export const getOrderDetailJson = {
  method: "get",
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
  authenticate: true,
  url: "v1/users/orders",
};

export const addToCart = {
  method: "post",
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
  url: "v1/users/cart",
  authenticate: true,
};

export const postPlaceOrder = {
  method: "post",
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
  url: "v1/users/placeorder",
  authenticate: true,
};

export const deleteCartItem = {
  method: "delete",
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
  url: "v1/users/remove-cart",
  authenticate: true,
};

export const categoryViewDetail = {
  method: "get",
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
  url: "v2/users/category",
  authenticate: true,
};

export const categoryInfoViewDetail = {
  method: "get",
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
  url: "v2/users/category-info",
  authenticate: true,
};

export const postPaymentStatusUpdate = {
  method: "get",
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
  url: "v1/users/postPaymentStatus",
  authenticate: true,
};

export const getCartCountRequest = {
  method: "get",
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
  url: "v1/users/cart-count",
  authenticate: true,
};

export const PostFbTokenRequestOptions = {
  method: "POST",
  headers: {
    accept: `application/json`,
    "Content-Type": `application/json`,
  },
  authenticate: true,
  url: "v1/users/post-user-token",
};

export const resetRequestOptions = {
  method: "POST",
  headers: {
    accept: `application/json`,
    "Content-Type": `application/json`,
  },
  url: "v1/users/reset",
};

export const resetLoginPasswordOptions = {
  method: "POST",
  headers: {
    accept: `application/json`,
    "Content-Type": `application/json`,
  },
  url: "v1/users/reset/login",
};
//all static const goes here

const tokenName = process.env.REACT_APP_KEY_TOKEN_NAME;
const notLoggedInErrorMessage = "Please login to use this feature";
const notLoggedInErrorTitle = "Login Required";
const appName = "Eat and Drink";
const pageUrl = "pageUrl";
const locName = "Location Name";
const Lat = "lat";
const Long = "long";
const click = "clickState";
const runningOrderDetails = "runningOrderDetails";
const searchKey = "searchKeyword";
export const fbTokenString = "fbToken";

// all functions goes here
function setToken(token) {
  return localStorage.setItem(tokenName, token);
}

function getToken() {
  return localStorage.getItem(tokenName);
}

function removeToken() {
  return localStorage.removeItem(tokenName);
}

function setItem(name, token) {
  return localStorage.setItem(name, token);
}

function getItem(name) {
  return localStorage.getItem(name);
}

function removeItem(name) {
  return localStorage.removeItem(name);
}

function setPageUrl(urlCurrentPage) {
  return localStorage.setItem(pageUrl, urlCurrentPage);
}

function getPageUrl() {
  return localStorage.getItem(pageUrl);
}

function removePageUrl() {
  return localStorage.removeItem(pageUrl);
}

function setLocationName(locationName) {
  return localStorage.setItem(locName, locationName);
}

function getLocationName() {
  return localStorage.getItem(locName);
}

function removeLocationName() {
  return localStorage.removeItem(locName);
}

function setLat(LatValue) {
  return localStorage.setItem(Lat, LatValue);
}

function getLat() {
  return localStorage.getItem(Lat);
}

function removeLat() {
  return localStorage.removeItem(Lat);
}

function setLong(LongValue) {
  return localStorage.setItem(Long, LongValue);
}

function getLong() {
  return localStorage.getItem(Long);
}

function removeLong() {
  return localStorage.removeItem(Long);
}

function setPopupState(clickState) {
  return localStorage.setItem(click, clickState);
}

function getPopupState() {
  return localStorage.getItem(click);
}

function removePopupState() {
  return localStorage.removeItem(click);
}

function setSearchKeyword(searchKeyword) {
  return localStorage.setItem(searchKey, searchKeyword);
}

function getSearchKeyword() {
  return localStorage.getItem(searchKey);
}

function removeSearchKeyword() {
  return localStorage.removeItem(searchKey);
}

export function JwtTokenParser(token) {
  if (token && token.length > 0) {
    try {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      return JSON.parse(jsonPayload);
    } catch (ex) {
      return null;
    }
  } else {
    return null;
  }
}

export function JwtValid(token) {
  console.log("🚀 ~ file: standard.js ~ line 397 ~ JwtValid ~ token", token);
  if (token) {
    const JwtToken = JwtTokenParser(token);
    if (JwtToken) {
      const expiry = JwtToken?.exp;
      const expiryDate = new Date(expiry * 1000);
      const today = new Date();
      return !!moment(today?.toISOString()).isSameOrBefore(
        expiryDate?.toISOString()
      );
    }
    return false;
  }
  return false;
}

function getName() {
  const jwtTokenParsed = JwtTokenParser(getToken());
  if (jwtTokenParsed && jwtTokenParsed?.name) {
    return jwtTokenParsed?.name;
  }
  return "Guest";
}

export {
  getToken,
  removeToken,
  setToken,
  getName,
  setItem,
  getItem,
  removeItem,
  setPageUrl,
  getPageUrl,
  removePageUrl,
  setLocationName,
  getLocationName,
  removeLocationName,
  setLat,
  getLat,
  removeLat,
  setLong,
  getLong,
  removeLong,
  setPopupState,
  getPopupState,
  removePopupState,
  setSearchKeyword,
  getSearchKeyword,
  removeSearchKeyword,
  runningOrderDetails,
  appName,
  tokenName,
  notLoggedInErrorMessage,
  notLoggedInErrorTitle,
};
