import { getToken } from "./standard";
async function ApiRunner(requestOptions) {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  if (requestOptions?.authenticate === true) {
    const token = getToken();
    if (token && token?.length > 0) {
      requestOptions.headers[`Authorization`] = `Basic ${token}`;
    }
  }
  const response = await fetch(
    `${BASE_URL}${requestOptions?.url}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((result) => {
      return result;
    });
  return response;
}

export { ApiRunner };
