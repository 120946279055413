import { useEffect } from "react";

const PrivacyPolicyPOS = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="container-fluid bg-white mt-5 pt-5 page-min-height">
        <div className="row">
          <div className="col-md-12">
            <div className="container-fluid mt-5 mb-5">
              <h2 className="PrivacyHeader">PRIVACY POLICY</h2>
              <h5 className="PrivacyHeader1">1. WHO WE ARE AND WHAT WE DO</h5>
              <p className="TextHederprivacy">
                1.1 We are Eat and Drink and our address is villa 594 B Al Wasl
                Road Dubai United Arab Emirates with email contact (“Eat and
                Drink”, “we”, “us”, “our”). Eat and Drink is a business name of
                Eat and Drink Restaurant LLC. Eat and Drink provides the
                following products and services (“Products”):
              </p>
              <ul>
                <li className="TextHederprivacy">
                  Eat and Drink POS – a cloud-based restaurant management
                  solution.
                </li>
              </ul>
              <p className="TextHederprivacy">
                1.2 In providing the Products, Eat and Drink will process
                personal data. This document sets out how Eat and Drink
                processes personal data for both Eat and Drink POS. This
                includes personal data collected when you use our website
                (“Website”) and Products (together, “Services”). 
              </p>

              <h5 className="PrivacyHeader1">
                2. WHAT DOES THIS POLICY COVER?
              </h5>
              <p className="TextHederprivacy">
                2.1 Eat and Drink takes your personal data seriously and
                processes it in accordance with applicable laws and regulations.
                Eat and Drink acts as the ‘controller’ (i.e., the person
                responsible for deciding how your personal data is processed)
                and has adopted this privacy policy (“Policy”) to establish and
                maintain the privacy and security of your personal data.
              </p>
              <p className="TextHederprivacy">
                This Policy:
                <ul>
                  <li>
                    (a) sets out the types of personal data we collect about
                    you;
                  </li>
                  <li>
                    (b) explains how and why we collect and use your personal
                    data;
                  </li>
                  <li>(c) explains how long we keep your personal data;</li>
                  <li>
                    (d) explains how we will share your personal data – when,
                    why and who with;
                  </li>
                  <li>
                    (e) explains the different rights and choices you have when
                    it comes to your personal data;
                  </li>
                  <li>
                    (f) explains the security measures we apply to protect your
                    personal data;
                  </li>
                  <li>
                    (g) explains how we may contact you and how you can contact
                    us.
                  </li>
                </ul>
              </p>
              <p className="TextHederprivacy">
                2.2 By accepting this Policy and submitting any personal data to
                us, you agree that we may collect, use, disclose and retain such
                data in accordance with this Privacy Policy, and as permitted or
                required by law. If you do not agree with these terms, then
                please do not accept them or provide any personal data to us.
              </p>
              <p className="TextHederprivacy">
                2.3 Please note that we will not be able to respond to you,
                provide the relevant Services to you, or deal with your
                enquiries unless you provide us with the required personal data
                as set out or referred to in this Privacy Policy, unless we
                indicate the supply of the personal data is optional.
              </p>

              <h5 className="PrivacyHeader1">
                3. WHAT PERSONAL DATA DO WE COLLECT ABOUT YOU AND HOW DO WE
                COLLECT IT?
              </h5>
              <p className="TextHederprivacy">
                3.1 We collect the content, communications, and other
                information you provide when you use our Services, including
                when you sign up for an account (if relevant) or communicate
                with us including by email. This includes your name, email
                address, phone number, name of your business (where relevant),
                location/country, and payment information (where relevant).
              </p>
              <p className="TextHederprivacy">
                3.2 In addition, we also collect the following personal data:
                <ul>
                  <li>
                    (a) Usage: we collect information about how you use our
                    Services, such as the time, frequency, and duration of your
                    browsing activities, and how you use the features provided
                    by the Services.
                  </li>
                  <li>
                    (b) Device information: we collect information on the
                    devices you use, such as the model and kind of the device
                    you are using to access our Services, the operating system,
                    hardware and software version together with other technical
                    information (e.g., your IP address).
                  </li>
                  <li>
                    (c) Your opinions about our products and services:
                    information you share rating your experiences and
                    satisfaction with the provision or receipt of our products
                    or services or otherwise dealing with us.
                  </li>
                </ul>
              </p>
              <h5 className="PrivacyHeader1">4. COOKIES</h5>
              <p className="TextHederprivacy">
                4.1 Where you use our Website, we also use cookies to collect
                personal data from your device to help us enhance the Services
                provided and to ease your access to our services. For example,
                they allow you to repeatedly use our services on the same device
                without having to log in or adjust the preference settings each
                time. We collect web statistics automatically about your visit
                to our Website based on your IP address. This information is
                used to help us improve your experience on our Website.
              </p>

              <h5 className="PrivacyHeader1">
                5. WHERE DO WE COLLECT PERSONAL DATA ABOUT YOU FROM?
              </h5>
              <p className="TextHederprivacy">
                5.1 We collect your personal data directly from you, i.e., this
                is the personal data you provide to us when using our Services
                or communicating with us. We also collect your personal data by
                deploying cookies as described above. We may also collect
                personal data from you when you visit our offices (e.g., through
                the use of CCTV).
              </p>

              <h5 className="PrivacyHeader1">
                6. HOW AND WHY DO WE USE YOUR PERSONAL DATA?
              </h5>
              <p className="TextHederprivacy">
                6.1 We use your personal data for the following purposes:
                <ul>
                  <li>
                    (a) Send you information in connection with our products and
                    services.
                  </li>
                  <li>(b) Respond to your communications and requests.</li>
                  <li>
                    (c) Manage our relationship with you or your organisation.
                  </li>
                  <li>
                    (d) Manage our relationship with third-party partners and
                    referrers.
                  </li>
                  <li>(e) Billings and payment.</li>
                  <li>(f) Deal with any disputes.</li>
                  <li>(g) Provide our Services to you.</li>
                  <li>
                    (h) Marketing – contact you regarding products and services.
                  </li>
                  <li>
                    (i) Customize the content and services we provide through
                    our Services.
                  </li>
                  <li>
                    (j) Help us better understand your interests and improve our
                    Services.
                  </li>
                  <li>
                    (k) Engage in analysis, research, and reports regarding use
                    of our Services.
                  </li>
                  <li>(l) Training and archiving.</li>
                  <li>(m) Secure our Services and systems.</li>
                  <li>(n) Comply with procedures, laws, and regulations.</li>
                  <li>(o) Establish, exercise, or defend our legal rights.</li>
                  <li>(p) Prevent and detect crime and fraud.</li>
                  <li>(q) Protect health, safety, and security.</li>
                  <li>(r) Comply with contractual requirements.</li>
                  <li>(s) Carry out internal business purposes.</li>
                </ul>
              </p>
              <h5 className="PrivacyHeader1">
                7. HOW LONG DO WE KEEP YOUR PERSONAL DATA FOR?
              </h5>
              <p className="TextHederprivacy">
                7.1 The retention period for personal data depends on the
                purposes for which it was collected and applicable laws. Once
                the data is no longer needed, reasonable steps will be taken to
                erase it.
              </p>
              <h5 className="PrivacyHeader1">
                8. WHO DO WE SHARE YOUR PERSONAL DATA WITH?
              </h5>
              <p className="TextHederprivacy">
                We may share your personal data with:
                <ul>
                  <li>(a) Businesses within our group.</li>
                  <li>(b) New owners in case of business ownership changes.</li>
                  <li>(c) Third-party service providers and partners.</li>
                  <li>(d) Government authorities as required by law.</li>
                  <li>
                    (e) Our employees, agents, consultants, and contractors.
                  </li>
                  <li>
                    (f) Banks, payment providers, and payment technology
                    providers.
                  </li>
                </ul>{" "}
              </p>

              <h5 className="PrivacyHeader1">
                9. THIRD-PARTY LINKS AND FEATURES
              </h5>
              <p className="TextHederprivacy">
                9.1 Our Website may contain links to third-party websites.
                Please review their privacy policies as we are not responsible
                for their practices.
              </p>

              <h5 className="PrivacyHeader1">
                10. STORAGE AND INTERNATIONAL TRANSFERS
              </h5>
              <p className="TextHederprivacy">
                10.1 We ensure compliance with requirements for international
                transfers of personal data under applicable laws.
              </p>

              <h5 className="PrivacyHeader1">
                11. SECURITY OF YOUR PERSONAL DATA
              </h5>
              <p className="TextHederprivacy">
                11.1 We implement measures to protect personal data from
                unauthorized access or loss, though no system is completely
                secure.
              </p>

              <h5 className="PrivacyHeader1">12. CHANGES TO THIS POLICY</h5>
              <p className="TextHederprivacy">
                12.1 We may update this Policy from time to time. Changes will
                take effect immediately upon posting.
              </p>

              <h5 className="PrivacyHeader1">13. YOUR RIGHTS</h5>
              <p className="TextHederprivacy">
                13.1 You have the following rights:
                <ul>
                  <li>
                    (a) The right to be informed about data collection and use.
                  </li>
                  <li>(b) The right to access your personal data.</li>
                  <li>
                    (c) The right to request correction or updating of your
                    data.
                  </li>
                  <li>
                    (d) The right to request deletion of unnecessary personal
                    data.
                  </li>
                  <li>(e) The right to withdraw consent at any time.</li>
                </ul>
              </p>
              <h5 className="PrivacyHeader1">14. HOW CAN YOU CONTACT US?</h5>
              <p className="TextHederprivacy">
                14.1 If you have concerns or questions about your personal data,
                please contact us:
              </p>
              <p className="TextHederprivacy">
                Address: Privacy at Eat and Drink Restaurant LLC Dubai 65892
              </p>
              <p className="TextHederprivacy">
                Email us at info@eatanddrink.ae
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PrivacyPolicyPOS;
