import { useState } from "react";
import { BiLock } from "react-icons/bi";
import { FiMail } from "react-icons/fi";
import { postReset } from "../features/sign-in/forgotpasswordSlice";
import { useDispatch } from "react-redux";
import { Pane, Spinner } from "evergreen-ui";
import { Link, useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [emailValidation, setEmailValidation] = useState(false);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);

  const passwordresetRequest = (e) => {
    if (email === "") {
      setEmailValidation(true);
    } else {
      dispatch(postReset({ email: email }));
      navigate("/reset-password");
    }
    setLoading(false);
  };

  return (
    <div>
      {isLoading ? (
        <div className="bg-white mt-5 pt-5 page-min-height">
          <div className="container rs-page">
            <div className="row">
              <Pane>
                <Spinner marginX="auto" marginY={120} />
              </Pane>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid mt-5 pt-5 pb-5 mb-5 bg-white page-min-height">
          <div className="row d-flex justify-content-center">
            <div className="col-6 col-md-6">
              <div className="card text-center">
                <div className="card-header">
                  <BiLock size={80} className="icon-lock" />
                </div>
                <div className="card-body">
                  <h1 className="reset-card-tittle">Forgot Password?</h1>
                  <div className="mb-3 resetText">
                    <label className="form-label edr-dft-text-gray "></label>
                    <div className="col">
                      <input
                        value={email}
                        type="email"
                        className="form-control"
                        placeholder="Enter your email"
                        id="resetInput"
                        icon={<FiMail />}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        onKeyUp={() => {
                          setEmailValidation(false);
                        }}
                      />
                      {emailValidation === true ? (
                        <div className="text-danger p-3">
                          *Please Enter Your Email Address
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <button
                      type="button"
                      className="btn  bg-edr-yellow text-white resetButton"
                      onClick={() => {
                        passwordresetRequest();
                      }}
                    >
                      Send
                    </button>
                    <Link type="button" to="/sign-in" className="resetText1">
                      Back to Sign In
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default ForgotPassword;
