import { useEffect, useState } from "react";
import { BiLockOpen } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import {
  postResetPassword,
  resetPasswordData,
} from "../features/sign-in/resetpasswordSlice";
import { FiMail } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { Pane, Spinner } from "evergreen-ui";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const [emailValidation, setEmailValidation] = useState(false);
  const [email, setEmail] = useState("");
  const [otpValidation, setOtpValidation] = useState(false);
  const [OTP, setOTP] = useState("");
  const [passwordValidation, setpasswordValidation] = useState(false);
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const resetStatus = useSelector(resetPasswordData);
  const [isLoading, setLoading] = useState(false);

  const resetLoginPassword = (e) => {
    if ((email === "", OTP === "", password === "")) {
      setEmailValidation(true);
      setOtpValidation(true);
      setpasswordValidation(true);
    } else {
      dispatch(
        postResetPassword({
          email: email,
          resetpass: OTP,
          password: password,
        })
      );
    }
  };

  useEffect(() => {
    setLoading(true);
    if (resetStatus?.user?.status) {
      navigate("/sign-in");
    }
    else{
      setLoading(false);
    }
  }, [navigate, resetStatus?.user?.status]);

  return (
    <div>
      {isLoading ? (
        <div className="bg-white mt-5 pt-5 page-min-height">
          <div className="container rs-page">
            <div className="row">
              <Pane>
                <Spinner marginX="auto" marginY={120} />
              </Pane>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid mt-5 pt-5 pb-5 mb-5 bg-white page-min-height">
          <div className="row d-flex justify-content-center">
            <div className="col-6 col-md-6">
              <div className="card text-center">
                <div className="card-header">
                  <BiLockOpen size={80} className="icon-lock" />
                </div>
                <div className="card-body">
                  <h2 className="reset-card-tittle">Set your New Password</h2>
                  <div className="mb-3 resetText">
                    <div className="col">
                      <label className="form-label edr-dft-text-gray "></label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        id="resetInput"
                        icon={<FiMail />}
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        onKeyUp={() => {
                          setEmailValidation(false);
                        }}
                      />
                      {emailValidation === true ? (
                        <div className="text-danger p-3">
                          *Please Enter Your Email Address
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <label className="form-label edr-dft-text-gray "></label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="OTP"
                      tabIndex="1"
                      autoComplete="off"
                      value={OTP}
                      id="resetInput"
                      onChange={(e) => {
                        setOTP(e.target.value);
                      }}
                      onKeyUp={() => {
                        setOtpValidation(false);
                      }}
                    />
                    {otpValidation === true ? (
                      <div className="text-danger p-3">
                        *Please Enter Your OTP
                      </div>
                    ) : (
                      <></>
                    )}
                    <label className="form-label edr-dft-text-gray "></label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=" Password"
                      tabIndex="1"
                      autoComplete="off"
                      value={password}
                      id="resetInput"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      onKeyUp={() => {
                        setpasswordValidation(false);
                      }}
                    />
                    {passwordValidation === true ? (
                      <div className="text-danger p-3">
                        *Please Enter Your New Password
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="row justify-content-center">
                    <button
                      type="button"
                      className="btn  bg-edr-yellow text-white resetButton1"
                      onClick={() => {
                        resetLoginPassword();
                      }}
                    >
                      Change Password
                    </button>
                    <Link type="button" to="/sign-in" className="resetText1">
                      Back to Sign In
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
      )}
    </div>
  );
};
export default ResetPassword;
