import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiRunner } from "../../services/communicator";
import {
  homeRequestOptions,
  homeGuestRequestOptions,
} from "../../services/standard";

export const getnearbyShops = createAsyncThunk(
  "restaurant/getnearbyShops",
  async (obj) => {
    const requestOptions = Object.assign({}, homeRequestOptions);
    requestOptions.url = homeRequestOptions.url + obj.lat + "/" + obj.long;
    const response = await ApiRunner(requestOptions);
    return response;
  }
);

export const getnearbyShopsGuest = createAsyncThunk(
  "restaurant/getnearbyShopsGuest",
  async (obj) => {
    const requestOptions = Object.assign({}, homeGuestRequestOptions);
    requestOptions.url = homeGuestRequestOptions.url + obj.lat + "/" + obj.long;
    const response = await ApiRunner(requestOptions);
    return response;
  }
);

export const homeSlice = createSlice({
  name: "restaurant",
  initialState: {
    status: "idle",
    lat: "25.2048",
    long: "55.2708",
    // _id: { data:{}},
    nearbyShops: { data: {} },
    googlePlaceAddress: "",
    googlePlaceTriggered: false,
  },
  reducers: {
    setGooglePlaceAddress: (state, action) => {
      state.googlePlaceAddress = action.payload;
    },
    setLatLong: (state, action) => {
      const { lat, lng } = JSON.parse(action.payload);
      state.lat = lat;
      state.long = lng;
    },
    setGooglePlaceTriggered: (state, action) => {
      state.googlePlaceTriggered = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getnearbyShops.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getnearbyShops.fulfilled, (state, action) => {
        state.status = "complete";
        state.nearbyShops = action.payload;
      })
      .addCase(getnearbyShopsGuest.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getnearbyShopsGuest.fulfilled, (state, action) => {
        state.status = "complete";
        state.nearbyShops = action.payload;
      });
  },
});
export const { setGooglePlaceAddress, setLatLong, setGooglePlaceTriggered } =
  homeSlice.actions;

export const selectNearbyShops = (state) => state.restaurant;

export default homeSlice.reducer;