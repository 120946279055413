import { configureStore } from "@reduxjs/toolkit";
import signinSlice from "../features/sign-in/signinSlice";
import signupSlice from "../features/sign-in/signupSlice";
import homeSlice from "../features/restaurant/homeSlice";
import restaurantSlice from "../features/restaurant/restaurantSlice";
import cartSlice from "../features/restaurant/cartSlice";
import addressSlice from "../features/restaurant/addressSlice";
import profileSlice from "../features/sign-in/profileSlice";
import orderSlice from "../features/order/orderSlice";
import CategoryViewSlice from "../features/restaurant/CategoryViewSlice";
import seeAllSlice from "../features/restaurant/seeAllSlice";
import searchSlice from "../features/restaurant/searchSlice";
import headerSlice from "../features/restaurant/headerSlice";
import resetpasswordSlice from "../features/sign-in/resetpasswordSlice";
import paymentStatusSlice from "../features/sign-in/paymentStatusSlice";
import ratingSlice from "../features/rating/ratingSlice";

export const store = configureStore({
  reducer: {
    signin: signinSlice,
    signup: signupSlice,
    restaurant: homeSlice,
    product: restaurantSlice,
    nameProduct: restaurantSlice,
    cart: cartSlice,
    primaryAddress: addressSlice,
    profile: profileSlice,
    trackOrder: orderSlice,
    order: orderSlice,
    orderDetail: orderSlice,
    categoryView: CategoryViewSlice,
    seeAll: seeAllSlice,
    searchProduct: searchSlice,
    allAddress: addressSlice,
    cartCount: headerSlice,
    reset: resetpasswordSlice,
    paymentSuccess:paymentStatusSlice,
    rating:ratingSlice
  },
});
