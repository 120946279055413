import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addAddress,
  selectPrimaryAddress,
} from "../features/restaurant/addressSlice";
import AddressGoogleMap from "../components/AddressGoogleMap";
import { useNavigate } from "react-router-dom";
import { Autocomplete } from "@react-google-maps/api";
import { statusIsGoogleMapsLoaded } from "../features/restaurant/headerSlice";
import { toasterService, toasterType } from "../services/toasterService";
import { Pane, Spinner } from "evergreen-ui";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";

const AddAddress = () => {
  const navigate = useNavigate();
  const selectPrimaryAddressApiData = useSelector(selectPrimaryAddress);
  const selectPrimaryAddressApi = selectPrimaryAddressApiData?.result;
  const address = selectPrimaryAddressApi?.data;
  const [autocomplete, setAutocomplete] = useState();
  const [lat, setLat] = useState(25.3461806);
  const [long, setLong] = useState(55.4210708);
  const [mobile, setMobile] = useState("");
  const [countryCode, setCountryCode] = useState("+971");
  const [area, setAddArea] = useState("");
  const [city, setAddCity] = useState("");
  const [landmark, setAddLandmark] = useState("");
  const [house, setAddHouse] = useState("");
  const [locality, setAddLocality] = useState("");
  const [country, setAddCountry] = useState("");
  const [googleResponse, setGoogleResponse] = useState("");
  const [googleAutocompleteValue, setGoogleAutocompleteValue] = useState("");
  const [isLoading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const newAddress = async (e, key) => {
    e.preventDefault();
    const isMobileValid = new RegExp(/^[0-9\b]+$/);
    if (mobile === "") {
      toasterService(toasterType.danger, "Please enter valid mobile number");
    } else if (!isMobileValid) {
      toasterService(toasterType.danger, "Please enter valid mobile number");
    } else if (String(mobile).length <= 5) {
      toasterService(toasterType.danger, "Please enter valid mobile number");
    } else {
      key = { key };
      dispatch(
        addAddress({
          addressId: address?.address[0]?._id,
          house: house,
          city: city,
          area: area,
          landmark: landmark,
          locality: locality,
          mobile: mobile,
          countryCode: countryCode,
          longitude: long,
          latitude: lat,
          country: country,
          google_address_string: googleResponse,
        })
      );
      navigate(`/primaryaddresslist`);
    }
  };

  function autocompleteHandleCallback(autocompleteResult) {
    setAddLocality("");
    setAddCity("");
    setAddCountry("");
    setAddArea("");
    setAddHouse("");
    setLat(autocompleteResult?.geometry?.location.lat());
    setLong(autocompleteResult?.geometry?.location.lng());
    googleMapsResponseParser(autocompleteResult);
  }

  const googleMapsResponseParser = (autocompleteResult) => {
    let googleResponse = "";
    let landmark = "";
    let area = "";
    let locality = "";
    let streetAddress = "";
    let country = "";
    autocompleteResult?.address_components.forEach((address, key) => {
      key = { key };
      if (address.types.length > 0) {
        setLoading(true);
        address.types.every((type) => {
          if (type === "plus_code") {
            return false;
          } else {
            setLoading(false);
            if (googleResponse?.length > 0) {
              googleResponse += " - ";
            }
            googleResponse += address?.long_name;
          }
          if (type === "route") {
            landmark = address.long_name;
            return false;
          }
          if (type === "locality") {
            locality = address.long_name;
            return false;
          }
          if (type === "country" || type === "political") {
            country = address.long_name;
            return false;
          }
          if (
            type === "neighborhood" ||
            type === "sublocality" ||
            type === "sublocality_level_1"
          ) {
            area =
              area.length > 0
                ? `${area}, ${address.long_name}`
                : address.long_name;
            return false;
          }
          if (type === "street_number" || type === "street_address") {
            streetAddress =
              streetAddress.length > 0
                ? `${streetAddress}, ${address.long_name}`
                : address.long_name;
            return false;
          }
          return true;
        });
        if (landmark && landmark.length > 0) {
          setAddLocality(locality);
        }
        if (locality && locality.length > 0) {
          setAddCity(locality);
        }
        if (area && area.length > 0) {
          setAddArea(area);
        }
        if (country && country.length > 0) {
          setAddCountry(country);
        }
        if (streetAddress && streetAddress.length > 0) {
          setAddHouse(streetAddress);
        }
      }
    });
    setGoogleAutocompleteValue(googleResponse);
    setGoogleResponse(googleResponse);
  };

  const mapCallback = async (latLng) => {
    const geocoder = new window.google.maps.Geocoder();
    let geocoderResponse = await geocoder.geocode({ location: latLng });
    geocoderResponse = geocoderResponse?.results;
    if (geocoderResponse && geocoderResponse?.length > 0) {
      setLat(latLng?.lat);
      setLong(latLng?.lng);
      googleMapsResponseParser(geocoderResponse[0]);
    }
  };

  const map = (
    <AddressGoogleMap
      origin={{
        lat: lat,
        lng: long,
      }}
      mapCallback={mapCallback}
    />
  );

  const isLoaded = useSelector(statusIsGoogleMapsLoaded);

  const onLoad = (autocompleteParam) => {
    setAutocomplete(autocompleteParam);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const autocompleteData = autocomplete.getPlace();
      autocompleteHandleCallback(autocompleteData);
    } else {
      toasterService(toasterType.warning, "Maps seems offline now");
    }
  };
  return (
    <div>
      {isLoading ? (
        <div className="bg-white mt-5 pt-5 page-min-height">
          <div className="container rs-page">
            <div className="row">
              <Pane>
                <Spinner marginX="auto" marginY={120} />
              </Pane>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid py-5 mt-5 pt-5 page-min-height">
          <div className="card mb-3">
            <div className="card-body">
              <h3 className="p-3 card-title">Add Address</h3>
              <div className="row d-flex justify-content-around">
                <div className="col-md-5">
                  <div className="mb-3">
                    <input
                      type="hidden"
                      className="form-control text-line"
                      id="lat"
                      value={lat}
                      onChange={(e) => setLat(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="hidden"
                      className="form-control text-line"
                      id="lang"
                      value={long}
                      onChange={(e) => setLong(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    {isLoaded ? (
                      <Autocomplete
                        onLoad={onLoad}
                        onPlaceChanged={onPlaceChanged}
                        restrictions={{ country: "ae" }}
                      >
                        <input
                          type="text"
                          className="searchbox"
                          placeholder="Search your location"
                          onChange={(e) => {
                            setGoogleAutocompleteValue(e.target.value);
                          }}
                          value={googleAutocompleteValue}
                        />
                      </Autocomplete>
                    ) : (
                      <></>
                    )}
                  </div>

                  <form
                    className="was-validated"
                    onSubmit={(e) => newAddress(e)}
                  >
                    <div className="mb-3">
                      <label htmlFor="city" className="form-label">
                        City
                      </label>
                      <input
                        className="form-control  text-line"
                        id="city"
                        onChange={(e) => setAddCity(e.target.value)}
                        value={city}
                        required
                      ></input>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="area" className="form-label">
                        Area
                      </label>
                      <input
                        className="form-control  text-line"
                        id="area"
                        onChange={(e) => setAddArea(e.target.value)}
                        value={area}
                        required
                      ></input>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="house" className="form-label">
                        House
                      </label>
                      <input
                        className="form-control  text-line"
                        id="house"
                        onChange={(e) => setAddHouse(e.target.value)}
                        value={house}
                        required
                      ></input>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="locality" className="form-label">
                        Locality
                      </label>
                      <input
                        className="form-control  text-line"
                        id="locality"
                        onChange={(e) => setAddLocality(e.target.value)}
                        value={locality}
                        required
                      ></input>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="landmark" className="form-label">
                        Landmark
                      </label>
                      <input
                        className="form-control  text-line"
                        id="landmark"
                        onChange={(e) => setAddLandmark(e.target.value)}
                        value={landmark}
                        required
                      ></input>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="country" className="form-label">
                        Country
                      </label>
                      <input
                        className="form-control  text-line"
                        id="country"
                        onChange={(e) => setAddCountry(e.target.value)}
                        value={country}
                        required
                      ></input>
                    </div>

                    <div className="mb-3">
                      <input
                        className="form-control  text-line"
                        type="hidden"
                        id="response"
                        onChange={(e) => setGoogleResponse(e.target.value)}
                        value={googleResponse}
                        required
                      ></input>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="mobile" className="form-label">
                        Mobile
                      </label>
                      <div className="d-flex justify-content-start">
                        <IntlTelInput
                          preferredCountries={["ae"]}
                          inputClassName="form-control text-line"
                          onSelectFlag={(
                            currentNumber,
                            selectedCountryData,
                            fullNumber,
                            isValid
                          ) => {
                            setCountryCode(selectedCountryData?.dialCode);
                          }}
                        />
                        <input
                          className="form-control  text-line"
                          id="mobile"
                          onChange={(e) => setMobile(e.target.value)}
                          required
                        ></input>
                      </div>
                    </div>
                    <div className="mb-3">
                      <button
                        type="submit"
                        className="btn secondary-bg-color text-white"
                      >
                        Add New Address
                      </button>
                    </div>
                  </form>
                </div>
                <div className="col-md-5">{map}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default AddAddress;
