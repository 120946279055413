import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiRunner } from "../../services/communicator";
import {
  addToCart,
  restaurentGuestProductWithPgenationRequestOptions,
  restaurantProductRequestOptions,
  restaurantProductWithPagenationRequestOptions,
} from "../../services/standard";
import { toasterService, toasterType } from "../../services/toasterService";

export const getProductShops = createAsyncThunk(
  "product/getProductShops",
  async (obj) => {
    const requestOptions = Object.assign({}, restaurantProductRequestOptions);
    requestOptions.url = `${restaurantProductRequestOptions.url}/${obj.shopId}`;
    const response = await ApiRunner(requestOptions);
    return response;
  }
);

export const getShopByProduct = createAsyncThunk(
  "shopproduct/getProductShops",
  async (obj) => {
    const requestOptionsb = Object.assign(
      {},
      restaurantProductWithPagenationRequestOptions
    );
    requestOptionsb.url = `${restaurantProductWithPagenationRequestOptions.url}/${obj.shopId}/products?page=${obj.pageno}`;
    const response = await ApiRunner(requestOptionsb);
    return response;
  }
);

export const getGuestShopByProduct = createAsyncThunk(
  "guestProduct/getGuestShopByProduct",
  async (obj) => {
    const requestOptionsb = Object.assign(
      {},
      restaurentGuestProductWithPgenationRequestOptions
    );
    requestOptionsb.url = `${restaurentGuestProductWithPgenationRequestOptions.url}/${obj.shopId}/products/${obj.lat}/${obj.long}?page=${obj.pageno}`;

    const response = await ApiRunner(requestOptionsb);
    return response;
  }
);

export const addCart = createAsyncThunk("addcart/addCart", async (obj) => {
  const requestOptionsc = Object.assign({}, addToCart);
  requestOptionsc[`body`] = JSON.stringify(obj);
  const response = await ApiRunner(requestOptionsc);
  toasterService(
    response.status === true ? toasterType.success : toasterType.danger,
    response?.message
  );
  return response;
});

export const restaurantSlice = createSlice({
  name: "product",
  nameProduct: "nameProduct",
  addCart: "addCart",

  initialState: {
    status: "complete",
    isClearCart: false,
    result: { data: {} },
    resultProduct: { data: [] },
    resultAddCart: { data: [] },
  },

  reducers: {
    setIsClearCart: (state, action) => {
      state.isClearCart = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getProductShops.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getProductShops.fulfilled, (state, action) => {
        state.status = "complete";
        state.result = action.payload;
      });
    builder
      .addCase(getShopByProduct.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getShopByProduct.fulfilled, (state, action) => {
        state.status = "complete";
        state.resultProduct = action.payload;
      });
    builder
      .addCase(getGuestShopByProduct.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getGuestShopByProduct.fulfilled, (state, action) => {
        state.status = "complete";
        state.resultProduct = action.payload;
      });
    builder
      .addCase(addCart.pending, (state) => {
        state.status = "loading";
        state.isClearCart = false;
      })
      .addCase(addCart.fulfilled, (state, action) => {
        state.status = "complete";
        state.resultAddCart = action.payload;
        if (
          action.payload?.status === false &&
          action.payload?.payload?.clear_status === true
        ) {
          state.isClearCart = true;
        }
      });
  },
});

export const selectProduct = (state) => state.product;
export const selectShopProduct = (state) => state.nameProduct;
export const selectAddCart = (state) => state.addCart;
export const { setIsClearCart } = restaurantSlice.actions;

export default restaurantSlice.reducer;
