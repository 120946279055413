import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiRunner } from "../../services/communicator";
import {
  getCartCountRequest,
  PostFbTokenRequestOptions,
} from "../../services/standard";

export const getCartCountASyncThunk = createAsyncThunk(
  "cartCount/getCartCountASyncThunk",
  async (obj) => {
    const requestOptions = Object.assign({}, getCartCountRequest);
    const response = await ApiRunner(requestOptions);
    return response;
  }
);

export const postFbToken = createAsyncThunk(
  "user/postFbTokenASyncThunk",
  async (obj) => {
    const requestOptions = Object.assign({}, PostFbTokenRequestOptions);
    const requestBody = {
      firebasedeviceId: obj,
      deviceType: "mobile",
    };
    requestOptions[`body`] = JSON.stringify(requestBody);
    const response = await ApiRunner(requestOptions);
    console.log("🚀 ~ file: headerSlice.js ~ line 27 ~ response", response);
    return response;
  }
);

export const headerSlice = createSlice({
  name: "cartCount",
  initialState: {
    status: "idle",
    result: { data: {} },
    isGoogleMapsLoaded: false,
  },

  reducers: {
    setIsGoogleMapsLoaded: (state, action) => {
      state.isGoogleMapsLoaded = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCartCountASyncThunk.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCartCountASyncThunk.fulfilled, (state, action) => {
        state.status = "complete";
        state.result = action.payload;
      });
  },
});
export const getCartCountState = (state) => state.cartCount;
export const statusIsGoogleMapsLoaded = (state) =>
  state?.cartCount?.isGoogleMapsLoaded;

export const { setIsGoogleMapsLoaded } = headerSlice.actions;

export default headerSlice.reducer;
