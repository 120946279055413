import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiRunner } from "../../services/communicator";
import {
  getOrderDetailJson,
  getOrderJsonv2,
  getTrackOrderJson,
} from "../../services/standard";

export const getTrackOrder = createAsyncThunk(
  "trackOrder/getTrackOrder",
  async (obj) => {
    const requestOptions = Object.assign({}, getTrackOrderJson);
    requestOptions.url = getTrackOrderJson.url + "/" + obj.orderId;
    const response = await ApiRunner(requestOptions);
    return response;
  }
);

export const getOrder = createAsyncThunk("order/getOrder", async (obj) => {
  const requestOptions = Object.assign({}, getOrderJsonv2);
  requestOptions.url = getOrderJsonv2.url + "?page=" + obj.page;
  const response = await ApiRunner(requestOptions);
  return response;
});

export const getOrderDetail = createAsyncThunk(
  "orderDetail/getOrderDetail",
  async (obj) => {
    const requestOptions = Object.assign({}, getOrderDetailJson);
    requestOptions.url = getOrderDetailJson.url + "/" + obj.orderId;
    const response = await ApiRunner(requestOptions);
    return response;
  }
);

export const trackOrderSlice = createSlice({
  name: "trackOrder",
  nameOrder: "order",
  nameDetail: "orderDetail",
  initialState: {
    status: "idle",
    result: { data: {} },
    resultOrder: [],
    resultOrderDetail: { data: {} },
    isNextPage: false,
  },

  extraReducers: (builder) => {
    builder
      .addCase(getTrackOrder.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTrackOrder.fulfilled, (state, action) => {
        state.status = "complete";
        state.result = action.payload;
      })
      .addCase(getOrder.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getOrder.fulfilled, (state, action) => {
        state.status = "complete";
        const previousResultOrder = state.resultOrder;
        let resultOrder = [];

        if (action.payload.data[0]?.current_page === 1) {
          resultOrder = action.payload.data[0]?.data;
        } else {
          resultOrder = previousResultOrder.concat(
            action.payload?.data[0]?.data
          );
        }

        if (resultOrder[0]) {
          state.resultOrder = resultOrder;
        }
        state.isNextPage = action.payload.data[0]?.next_page;
      })
      .addCase(getOrderDetail.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getOrderDetail.fulfilled, (state, action) => {
        state.status = "complete";
        state.resultOrderDetail = action.payload;
      });
  },
});
export const selectTrackOrder = (state) => state.trackOrder;
export const selectOrder = (state) => state.order;

export default trackOrderSlice.reducer;
