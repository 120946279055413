import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { StarEmptyIcon, WalkIcon } from "evergreen-ui";
import Rectangle5 from "../images/Rectangle5.png";

const RestaurantCard = (props) => {
  const [className, setClass] = useState("");
  useEffect(() => {
    if (props.isOpen === true) {
      setClass("card vaas-card-hover vaas-res-card");
    } else {
      setClass("card vaas-card-hover vaas-res-card bg-edr-grayout");
    }
  }, [props.isOpen]);

  return (
    <div className="col-md-4 vaas-nru-mb">
      <div className="card vaas-res-card">
        <div className={className}>
          <Link to={`/Restaurent/${props.id}`}>
            <img
              className="card-img-top edr-card-image img-fluid"
              src={props?.shopImage ? props?.shopImage : Rectangle5}
              alt="card_image_cap"
            />{" "}
            {props?.rating >= 0 ? (
              <div className="float-start rating-hover-style">
                <span
                  className="badge rounded-pill bg-warning text-dark d-flex justify-content-center vertical-center"
                  style={{ width: "48px", height: "24px" }}
                >
                  <StarEmptyIcon> </StarEmptyIcon> {props?.rating}
                </span>
              </div>
            ) : (
              <></>
            )}
            {props?.distance >= 0 ? (
              <div className="float-end distance-hover-style">
                <span
                  className="badge rounded-pill bg-warning text-dark"
                  style={{ width: "130px", height: "24px" }}
                >
                  <WalkIcon> </WalkIcon>
                  {props?.distance > 20
                    ? `More than 20 Km`
                    : `${props?.distance}Km`}
                </span>
              </div>
            ) : (
              <></>
            )}
            {/* <img
                className="card-img-top edr-card-image img-fluid"
                src={props.shopImage}
                alt="card_image_cap" style={{ width: '100%', height: '15rem' }}
              /> */}
            <div className="mt-1">
              <span className="badge badge-pill bg-edr-red text-white edr-card-radius fs-7 vaas-overlay-closed-restaurant">
                {props?.isOpen ? "" : "Shop Closed"}
              </span>
              <span className="badge badge-pill bg-edr-red text-white edr-card-radius fs-7 vaas-overlay-restaurant">
                {props?.isDeliverable ? "" : "Undeliverable"}
              </span>
              {props?.cuisines && props?.cuisines?.length > 0
                ? props?.cuisines.map((cuisine, key) => {
                    return (
                      <span
                        key={key}
                        className="badge badge-pill bg-edr-green text-white edr-card-radius fs-7 ms-1 me-1"
                      >
                        {cuisine?.name}
                      </span>
                    );
                  })
                : ""}
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <h6 className="card-title">{props.name}</h6>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <p className="card-title">{props.address}</p>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default RestaurantCard;
