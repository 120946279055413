import React from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { useSelector } from "react-redux";
import { statusIsGoogleMapsLoaded } from "../features/restaurant/headerSlice";

const AddressGoogleMap = (props) => {
  const isLoaded = useSelector(statusIsGoogleMapsLoaded);

  let center = {
    lat: props.origin.lat,
    lng: props.origin.lng,
  };

  const onLoad = React.useCallback(function callback(map) {}, []);

  const onUnmount = React.useCallback(function callback(map) {}, []);

  const markerOnLoad = (marker) => {};

  const markerDragEnd = (event) => {
    // center = { lat: event?.latLng?.lat(), lng: event?.latLng?.lng() };
    props.mapCallback({
      lat: event?.latLng?.lat(),
      lng: event?.latLng?.lng(),
    });
  };

  return isLoaded ? (
    <>
      {/* {console.log("maps loaded", center)} */}
      <GoogleMap
        mapContainerStyle={{
          height: "100%",
          width: "100%",
        }}
        zoom={15}
        center={center}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <Marker
          draggable={true}
          position={center}
          label={props.shopName}
          onLoad={markerOnLoad}
          onDragEnd={markerDragEnd}
        />
      </GoogleMap>
    </>
  ) : (
    <></>
  );
};

export default AddressGoogleMap;
