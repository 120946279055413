import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { store } from "./app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "pace-js";
import "pace-js/themes/yellow/pace-theme-minimal.css";
import { registerServiceWorker } from "./firebaseVaas";

if (process.env.REACT_APP_SENTRY_ENABLED === "true") {
  Sentry.init({
    dsn: "https://1783ca2bbe20411b8b468bdbc380c120@o1096075.ingest.sentry.io/6119162",
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.REACT_APP_ENV,
    tracesSampleRate: process.env.REACT_APP_SENTRY_SAMPLE_RATE,
  });
}

if (process.env.REACT_APP_ENV === "Production") {
  if (window) {
    window.console.log =
      window.console.warn =
      window.console.info =
        function () {
          // Don't log anything.
        };
  }
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
// registerServiceWorker();
