import React from "react";
import { Link } from "react-router-dom";
import category from "../images/category.jpg";

const Category = (props) => {
  return (
    <div className="col bg-white bg-green cardPadding" id="card1">
      <div
        className="edr-card-category card vaas-card-hover pb-1 cardHover"
        style={{ width: "auto" }}
      >
        <Link to={`/CategoryView/${props?._id}`}>
          <div className="noPadding card-body">
            <img
              alt=""
              className="top-category-img card-img img-fluid"
              src={props?.category_image ? props?.category_image : category}
            />
            <h6 className="card-title text-center mt-2">{props?.name}</h6>
          </div>
        </Link>
      </div>
    </div>
  );
};
export default Category;
