import React, { useEffect } from "react";
import { HiExclamation } from "react-icons/hi";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  postPaymentFailed,
  paymentFailed,
} from "../features/sign-in/paymentStatusSlice";

const PaymentFailed = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const Ref = searchParams.get("ref");
  const failedData = useSelector(paymentFailed);
  const navigate = useNavigate();

  useEffect(() => {
    if (Ref && Ref != null) {
      dispatch(postPaymentFailed({ id: Ref }));
    } else {
      navigate(`/`);
    }
  }, [Ref, dispatch]);

  return (
    <>
      <div className="container-fluid mt-5 pt-5 pb-5 mb-5 bg-white">
        <div className="row">
          <div className="col">
            <h1 className="card text-center">
              <div className="card-header">
                <HiExclamation size={60} color="#FF0000" />
              </div>
              <h1 className="card-title">
                Payment Failed
                <p className="card-text">
                  {" "}
                  {failedData?.data?.transaction_details?.transaction_status}
                </p>
                <button className="btn bg-edr-yellow text-white">
                  <a href="/Cart" color="white">
                    Back to Cart
                  </a>
                </button>
              </h1>
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentFailed;
