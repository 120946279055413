import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiRunner } from "../../services/communicator";
import { signInV2RequestOptions } from "../../services/standard";
import { toasterService, toasterType } from "../../services/toasterService";

export const postLogin = createAsyncThunk("signin/postLogin", async (obj) => {
  const requestOptions = signInV2RequestOptions;
  requestOptions[`body`] = JSON.stringify(obj);
  const response = await ApiRunner(requestOptions);
  toasterService(
    response.status === true ? toasterType.success : toasterType.danger,
    response?.message
  );
  return response;
});

export const signinSlice = createSlice({
  name: "signin",
  initialState: {
    status: "idle",
    user: {},
  },

  extraReducers: (builder) => {
    builder
      .addCase(postLogin.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postLogin.fulfilled, (state, action) => {
        state.status = "idle";
        state.user = action.payload;
      });
  },
});

export const signInUser = (state) => state.signin;
export default signinSlice.reducer;
