import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiRunner } from "../../services/communicator";
import { profileRequestOptions, removeToken } from "../../services/standard";
import { editProfileRequestOptions } from "../../services/standard";

export const getProfile = createAsyncThunk("profile/getProfile", async () => {
  const requestOptions = profileRequestOptions;
  const response = await ApiRunner(requestOptions);
  return response;
});

export const updateProfile = createAsyncThunk(
  "profile/updateProfile",
  async (obj) => {
    const requestOptions = editProfileRequestOptions;
    requestOptions[`body`] = JSON.stringify(obj);
    const response = await ApiRunner(requestOptions);
    return response;
  }
);

export const profileSlice = createSlice({
  name: "profile",
  initialState: {
    status: "idle",
    profileUser: {},
    profileEdit: {},
    editName: "",
  },

  extraReducers: (builder) => {
    builder
      .addCase(getProfile.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.status = "complete";
        if (action?.payload.status) {
          state.profileUser = action?.payload;
          state.editName = action?.payload?.name;
        } else {
          removeToken();
        }
      });
    builder
      .addCase(updateProfile.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.status = "idle";
        state.profileEdit = action.payload;
      });
  },
});

export const selectProfile = (state) => state.profile;
export const selecteditName = (state) => state.profile.editName;
export default profileSlice.reducer;
