import React, { useEffect } from "react";

const DeliveryAppDownload = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="container-fluid mt-5 pt-5 pb-5 mb-5 bg-white page-min-height">
        <div className="row d-flex justify-content-center">
          <div className="col-6 col-md-6">
            <div className="card text-center">
              <div className="card-header">Delivery App Download List</div>
              <div className="card-body">
                <h1 className="card-title">
                  Delivery App
                  <br />
                  <br />
                  <h6 className="card-text">
                    Below given is the list fo available delivery apps for
                    download.
                  </h6>
                  <a href="Delivery_EDR_release_1.0.apk">
                    <button className="btn bg-edr-yellow text-white">
                      Download Delivery App - V1.0
                    </button>
                  </a>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      )
    </>
  );
};

export default DeliveryAppDownload;
