import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiRunner } from "../../services/communicator";
import { resetRequestOptions } from "../../services/standard";
import { toasterService, toasterType } from "../../services/toasterService";

export const postReset = createAsyncThunk("reset/postReset", async (obj) => {
  const requestOptions = resetRequestOptions;
  requestOptions[`body`] = JSON.stringify(obj);
  const response = await ApiRunner(requestOptions);
  toasterService(
    response.status === true ? toasterType.success : toasterType.danger,
    response?.message
  );
  return response;
});

export const forgotpasswordSlice = createSlice({
  name: "reset",
  initialState: {
    status: "idle",
    email: {},
  },

  extraReducers: (builder) => {
    builder
      .addCase(postReset.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postReset.fulfilled, (state, action) => {
        state.status = "idle";
        state.email = action.payload;
      });
  },
});

export const forgotPassword = (state) => state.reset;
export default forgotpasswordSlice.reducer;