import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import RestaurantCard from "../components/RestaurantCard";
import {
  getShops,
  selectSeeAllRestaurent,
} from "../features/restaurant/seeAllSlice";
import Pagination from "react-js-pagination";
import { Pane, Spinner } from "evergreen-ui";
import { selectPrimaryAddress } from "../features/restaurant/addressSlice";
import { getLat, getLong, getToken } from "../services/standard";

const SeeAll = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const recordPerPage = 10;
  const pageRange = 10;
  const token = getToken();

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const selectAllShopsData = useSelector(selectSeeAllRestaurent);
  const selectAllShops = selectAllShopsData?.result;
  const selectPrimaryAddressApiData = useSelector(selectPrimaryAddress);
  const selectPrimaryAddressApi = selectPrimaryAddressApiData?.result;
  const [isLoading, setLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (selectAllShopsData?.status === "complete") {
      if (token) {
        if (selectPrimaryAddressApiData?.status === "complete") {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    }
  }, [token, selectAllShopsData?.status]);

  useEffect(() => {
    if (token) {
      setLoading(true);
      if (selectPrimaryAddressApiData?.status === "complete") {
        // console.log(selectPrimaryAddressApiData?.status)
        dispatch(
          getShops({
            lat: selectPrimaryAddressApi?.data?.address[0]?.latitude,
            long: selectPrimaryAddressApi?.data?.address[0]?.longitude,
            page: currentPage,
          })
        );
      }
    } else {
      if (getLat() && getLong()) {
        dispatch(
          getShops({
            lat: getLat(),
            long: getLong(),
            page: currentPage,
          })
        );
      } else {
        function showPosition(position) {
          dispatch(
            getShops({
              lat: position?.coords?.latitude,
              long: position?.coords?.longitude,
              page: currentPage,
            })
          );
        }
        navigator?.geolocation?.getCurrentPosition(showPosition);
      }
    }
  }, [
    selectPrimaryAddressApiData?.status,
    selectPrimaryAddressApi?.data?.address,
    dispatch,
    currentPage,
    token,
  ]);

  return (
    <div>
      {isLoading ? (
        <div className="bg-white  mt-5 pt-5 page-min-height">
          <div className="container pt-5 rs-page">
            <div className="row">
              <Pane>
                <Spinner marginX="auto" marginY={120} />
              </Pane>
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-white  mt-5 pt-5 page-min-height">
          <div className="container pt-5 rs-page">
            <div className="row">
              <h4 className="fw-bold pt-5">All Restaurants</h4>
              {selectAllShops?.data[0]?.data?.length === 0 ? (
                <h3 className="fw-bold">No Restaurants Found</h3>
              ) : (
                ""
              )}
            </div>

            <div className="row mt-5">
              {selectAllShops?.data !== undefined ||
                selectAllShops?.data?.length !== 0
                ? selectAllShops?.data[0]?.data?.map((shop, key) => {
                  return (
                    <RestaurantCard
                      key={key}
                      name={shop?.name}
                      id={shop?._id}
                      isOpen={shop?.is_open}
                      isDeliverable={shop?.isDeliverable}
                      address={
                        shop?.address?.house +
                        ", " +
                        shop?.address?.area +
                        ", " +
                        shop?.address?.locality +
                        ", " +
                        shop?.address?.city
                      }
                      cuisines={shop?.cuisine}
                      rating={shop?.total_rating}
                      distance={shop?.distance}
                      shopImage={shop?.shop_image}
                    />
                  );
                })
                : ""}
            </div>
          </div>
        </div>
      )}
      <div className="bg-white">
        <div className="col-md-12 d-flex justify-content-center flex-row">
          {selectAllShops.data !== undefined ? (
            <Pagination
              itemClass="page-item" // add it for bootstrap 4
              linkClass="page-link" // add it for bootstrap 4
              activePage={currentPage}
              itemsCountPerPage={recordPerPage}
              totalItemsCount={selectAllShops.data[0]?.totalCount?.total_rows}
              pageRangeDisplayed={pageRange}
              onChange={handlePageChange}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
export default SeeAll;
