import { Pane, SideSheet, Spinner } from "evergreen-ui";
import React, { useEffect, useState } from "react";
import { IoCheckmarkCircle } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import OrderDetailComponent from "../components/OrderDetailComponent";
import { getCartCountASyncThunk } from "../features/restaurant/headerSlice";
import {
  postPaymentSuccess,
  paymentSuccessful,
  paymentFailed,
} from "../features/sign-in/paymentStatusSlice";
import { HiExclamation } from "react-icons/hi";
import {
  getToken,
  removeItem,
  runningOrderDetails,
} from "../services/standard";
import { getCart } from "../features/restaurant/cartSlice";
import { toasterService, toasterType } from "../services/toasterService";

const PaymentSuccess = () => {
  const [isLoading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const Ref = searchParams.get("ref");
  const type = searchParams.get("type");
  const successData = useSelector(paymentSuccessful);
  const successDataValue = successData?.paymentSuccess;
  const [showOrderDetail, setShowOrderDetail] = useState(false);
  const failedData = useSelector(paymentFailed);
  const token = getToken();
  const navigate = useNavigate();

  useEffect(() => {
    const payload = {};
    if (Ref && type && Ref != null) {
      payload[`id`] = Ref;
      if (type && type != null) {
        payload[`type`] = type;
      } else {
        payload[`type`] = "Ngenius";
      }
      async function fetchData() {
        setLoading(true);
        await dispatch(postPaymentSuccess(payload));
        await dispatch(getCartCountASyncThunk());
      }
      fetchData();
    } else {
      navigate(`/`);
    }
  }, [Ref, type, dispatch]);

  useEffect(() => {
    if (token && token?.length > 0) {
      dispatch(getCart());
    }
    if (successData?.status === "complete") {
      console.log(
        "🚀 ~ file: PaymentSuccess.js ~ line 60 ~ useEffect ~ successData",
        successData
      );
      if (type === "Ngenius") {
        if (successData?.paymentSuccess?.status) {
          setLoading(false);
          dispatch(getCartCountASyncThunk());
        } else {
          toasterService(
            toasterType.danger,
            "Order placement failed.",
            "EDR Delivery"
          );
          navigate(`/cart`);
        }
        removeItem(runningOrderDetails);
      } else if (type === "cod") {
        setLoading(false);
        dispatch(getCartCountASyncThunk());
        removeItem(runningOrderDetails);
      }
    }
  }, [successData?.status, dispatch]);

  window.onpopstate = function () {
    window.location.reload();
  };
  return (
    <>
      {isLoading ? (
        <div className="bg-white pt-5 mt-5">
          <div className="container rs-page">
            <div className="row">
              <Pane>
                <Spinner marginX="auto" marginY={120} />
              </Pane>
            </div>
          </div>
        </div>
      ) : successData?.paymentSuccess?.data?.transaction_details
        ?.transaction_type === "Online-Cod" ? (
        <div className="container-fluid mt-5 pt-5 pb-5 mb-5 bg-white page-min-height">
          <div className="row d-flex justify-content-center">
            <div className="col-6 col-md-6">
              <div className="card text-center">
                <div className="card-header">
                  <IoCheckmarkCircle size={60} color="green" />
                </div>
                <div className="card-body">
                  <h1 className="card-title">
                    Your Cash On Delivery Order Placed
                    <p className="card-text">
                      {/* if needed */}
                      {/* {console.log(
                          successData?.paymentSuccess?.data?.transaction_details
                            ?.transaction_status
                        )}
                        {
                          successData?.paymentSuccess?.data?.transaction_details
                            ?.transaction_status
                        } */}
                    </p>
                    <button
                      className="btn bg-edr-yellow text-white"
                      onClick={() => {
                        setShowOrderDetail(true);
                      }}
                    >
                      Order Details
                    </button>
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <SideSheet
            isShown={showOrderDetail}
            onCloseComplete={() => setShowOrderDetail(false)}
            preventBodyScrolling
          >
            {successDataValue?.data !== undefined ? (
              <>
                <OrderDetailComponent order_id={successDataValue?.data?._id} />
              </>
            ) : (
              <></>
            )}
          </SideSheet>
        </div>
      ) : successData?.paymentSuccess?.data?.transaction_details
        ?.transaction_status === "confirmed" ? (
        <div className="container-fluid mt-5 pt-5 pb-5 mb-5 bg-white page-min-height">
          <div className="row d-flex justify-content-center">
            <div className="col-6 col-md-6">
              <div className="card text-center">
                <div className="card-header">
                  <IoCheckmarkCircle size={60} color="green" />
                </div>
                <div className="card-body">
                  <h1 className="card-title">
                    Payment Successful
                    <p className="card-text">
                      {/* if needed */}
                      {/* {console.log(
                        successData?.paymentSuccess?.data?.transaction_details
                          ?.transaction_status
                      )}
                      {
                        successData?.paymentSuccess?.data?.transaction_details
                          ?.transaction_status
                      } */}
                    </p>
                    <button
                      className="btn bg-edr-yellow text-white"
                      onClick={() => {
                        setShowOrderDetail(true);
                      }}
                    >
                      Order Details
                    </button>
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <SideSheet
            isShown={showOrderDetail}
            onCloseComplete={() => setShowOrderDetail(false)}
            preventBodyScrolling
          >
            {successDataValue?.data !== undefined ? (
              <>
                <OrderDetailComponent order_id={successDataValue?.data?._id} />
              </>
            ) : (
              <></>
            )}
          </SideSheet>
        </div>
      ) : successData?.paymentSuccess?.data?.transaction_details
        ?.transaction_status === "failed" ? (
        <div className="container-fluid mt-5 pt-5 pb-5 mb-5 bg-white page-min-height">
          <div className="row d-flex justify-content-center">
            <div className="col-6 col-md-6">
              <h1 className="card text-center">
                <div className="card-header">
                  <HiExclamation size={60} color="#FF0000" />
                </div>
                <h1 className="card-title">
                  Payment Failed
                  <p className="card-text">
                    {" "}
                    {failedData?.data?.transaction_details?.transaction_status}
                  </p>
                  <button className="btn bg-edr-yellow text-white">
                    <a href="/Cart" color="white">
                      Back to Cart
                    </a>
                  </button>
                </h1>
              </h1>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default PaymentSuccess;
