import React from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { statusIsGoogleMapsLoaded } from "../features/restaurant/headerSlice";
import { useSelector } from "react-redux";

const styleNormal = {
  height: "100%",
  width: "100%",
};
const styleRounded = {
  height: "100%",
  width: "100%",
  borderTopLeftRadius: "20px",
  borderBottomLeftRadius: "20px",
};
const RestaurantGoogleMap = (props) => {
  const isLoaded = useSelector(statusIsGoogleMapsLoaded);

  //const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    //setMap(map)
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    //setMap(null)
  }, []);

  return isLoaded &&
    props?.origin?.lat &&
    props?.origin?.lng &&
    typeof props?.origin?.lat == "number" &&
    typeof props?.origin?.lng == "number" ? (
    <GoogleMap
      mapContainerStyle={props?.roundedStyle ? styleNormal : styleRounded}
      zoom={15}
      center={{
        lat: props.origin.lat,
        lng: props.origin.lng,
      }}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      <Marker
        position={props.origin}
        label={props.shopName}
        title={props.shopName}
      />
      {/* Child components, such as markers, info windows, etc. */}
    </GoogleMap>
  ) : (
    <></>
  );
};

export default RestaurantGoogleMap;
