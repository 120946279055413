import React from "react";
import {
  DirectionsRenderer,
  DirectionsService,
  GoogleMap,
  Marker,
} from "@react-google-maps/api";
import { statusIsGoogleMapsLoaded } from "../features/restaurant/headerSlice";
import { useSelector } from "react-redux";

const TrackOrderGoogleMap = (props) => {
  const isLoaded = useSelector(statusIsGoogleMapsLoaded);

  const center = {
    lat: props.origin.lat,
    lng: props.origin.lng,
  };

  //const [map, setMap] = React.useState(null);
  const [response, setResponse] = React.useState("");
  //const [zoom, setZoom] = React.useState(2);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    //setMap(map)
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    //setMap(null)
  }, []);

  function directionsCallback(response) {
    console.log(response);
    if (response !== null) {
      if (response.status === "OK") {
        setResponse(response);
      } else {
      }
    }
  }

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{
        height: "100%",
        width: "100%",
      }}
      zoom={10}
      center={center}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {props.destination !== "" && props.origin !== "" && (
        <DirectionsService
          // required
          options={{
            destination: props.destination,
            origin: props.origin,
            travelMode: "DRIVING",
          }}
          // required
          callback={directionsCallback}
          // optional
          onLoad={(directionsService) => {
            console.log(
              "DirectionsService onLoad directionsService: ",
              directionsService
            );
          }}
          // optional
          onUnmount={(directionsService) => {
            console.log(
              "DirectionsService onUnmount directionsService: ",
              directionsService
            );
          }}
        />
      )}
      {response !== null && (
        <DirectionsRenderer
          // required
          options={{
            directions: response,
            suppressMarkers: true,
            // markerOptions: { label: "Eat and Drink", position: props.origin },
          }}
          // optional
          onLoad={(directionsRenderer) => {
            console.log(
              "DirectionsRenderer onLoad directionsRenderer: ",
              directionsRenderer
            );
          }}
          // optional
          onUnmount={(directionsRenderer) => {
            console.log(
              "DirectionsRenderer onUnmount directionsRenderer: ",
              directionsRenderer
            );
          }}
        />
      )}
      <Marker
        position={props.origin}
        label={props.shopName}
        title={props.shopName}
      />
      <Marker position={props.destination} label={"You"} title="You" />
    </GoogleMap>
  ) : (
    <></>
  );
};

export default TrackOrderGoogleMap;
