import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiRunner } from "../../services/communicator";
import {
  categoryInfoViewDetail,
  categoryViewDetail,
} from "../../services/standard";

export const getCategoryDetail = createAsyncThunk(
  "categoryView/categoryViewDetail",
  async (obj) => {
    const requestOptions = Object.assign({}, categoryViewDetail);
    requestOptions.url = `${categoryViewDetail.url}/${obj.catId}/product/${obj?.lat}/${obj?.long}/?page=${obj.pageNo}`;
    const response = await ApiRunner(requestOptions);
    return response;
  }
);

export const getCategoryInfoDetail = createAsyncThunk(
  "categoryView/categoryInfoDetail",
  async (obj) => {
    const requestOptions = Object.assign({}, categoryInfoViewDetail);
    requestOptions.url = `${categoryInfoViewDetail.url}/${obj.catId}/product?page=${obj.pageNo}`;
    const response = await ApiRunner(requestOptions);
    return response;
  }
);

export const CategoryViewSlice = createSlice({
  name: "categoryView",
  initialState: {
    status: "idle",
    result: { data: [] },
    counter: 0,
  },

  reducers: {
    setCounter: (state,action) => {
      state.counter = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getCategoryDetail.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCategoryDetail.fulfilled, (state, action) => {
        state.status = "complete";
        state.result = action.payload;
      })
      .addCase(getCategoryInfoDetail.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCategoryInfoDetail.fulfilled, (state, action) => {
        state.status = "complete";
        state.result = action.payload;
      });
  },
});

export const { setCounter } = CategoryViewSlice.actions;
export const selectCategoryView = (state) => state.categoryView;
export const currentCatCounterValue = (state) => state.categoryView.counter;

export default CategoryViewSlice.reducer;
