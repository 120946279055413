import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiRunner } from "../../services/communicator";
import { signUpV2RequestOptions } from "../../services/standard";
import { toasterType, toasterService } from "../../services/toasterService";
export const postSignup = createAsyncThunk("signup/postSignup", async (obj) => {
  const requestOptions = signUpV2RequestOptions;
  requestOptions[`body`] = JSON.stringify(obj);
  const response = await ApiRunner(requestOptions);
  toasterService(
    response.status === true ? toasterType.success : toasterType.danger,
    response?.message
  );
  return response;
});

export const signupSlice = createSlice({
  name: "signup",
  initialState: {
    status: "idle",
    user: {},
  },

  extraReducers: (builder) => {
    builder
      .addCase(postSignup.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postSignup.fulfilled, (state, action) => {
        state.status = "idle";
        state.user = action.payload;
      });
  },
});

export const signUpUser = (state) => state.signup.user;

export default signupSlice.reducer;
