import React, { useEffect } from "react";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="container-fluid mt-5 pt-5 pb-5 bg-white page-min-height">
        <div className="row py-3">
          <div className="col text-center">
            <h2 className="AboutUs" style={{ fontSize: "42px" }}>
              ABOUT US
            </h2>
          </div>
        </div>
        <div className=" col-md-offset-3 jt_col column_container">
          <h6 className="section-subtitle">
            We love restaurants as much as you do. That’s why we’ve been helping
            them fill tables since 1982. Welcome to eat and drink group of
            restaurants.
          </h6>
        </div>
        <div className="col">
          <h6 className="heading font-smoothing">The History</h6>
          <p className="text">
            {" "}
            The <strong>History of Eat And Drink</strong> emerged from humble
            beginning to become a renowned name in the restaurant and catering
            industry. We started as a cafteria, serving fresh fruit juices and
            sandwiches. Branding Ourselves Eat and Drink after having
            established restaurants in Jumeirah and Al Quoz, we were well on our
            way to grater heights. We then proceeeded to hire chefs from
            renowned restaurnts in Dubai and from those around the world, the
            increasing demand for our food supurring us on to embrace a larger
            family of consumers.It is now our tradition to present locals and
            foreigners with a spread that will linger in their memories long
            past their first experience of our culinary prowess. We are renowned
            for our Chinese and Lebanese cuisine. Apart from updating our menu
            and introducing new items, we take pride in maintaining the highest
            standards in hygine and cleanliness.Today, we are one of the leading
            restaurants and catering chains in the UAE, With chain of stores in
            Dubai , Abu Dhabi , Sharjah , Ajman ,Ras Al Khaimah ,Fujairah and Al
            Ain.
          </p>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
