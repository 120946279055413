import { Dialog, Pane, Spinner, StarEmptyIcon } from "evergreen-ui";
import * as $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import FloatingCartComponent from "../components/FloatingCartComponent";
import Product from "../components/Product";
import RestaurantGoogleMap from "../components/RestaurantGoogleMap";
import { setIsPopupModal } from "../features/restaurant/cartSlice";
import { getCartCountASyncThunk } from "../features/restaurant/headerSlice";
import {
  addCart,
  getGuestShopByProduct,
  getProductShops,
  getShopByProduct,
  selectProduct,
  selectShopProduct,
  setIsClearCart,
} from "../features/restaurant/restaurantSlice";
import Rectangle5 from "../images/Rectangle5.png";
import {
  getLat,
  getLong,
  getToken,
  notLoggedInErrorMessage,
  setPageUrl,
} from "../services/standard";
import { toasterService, toasterType } from "../services/toasterService";
window["jQuery"] = window["$"] = $;

const Restaurant = () => {
  const addressModalReference = useRef();
  const [isShown, setIsShown] = useState(false);
  const shopNameData = useSelector(selectProduct);
  const shopName = shopNameData?.result;
  const productNameData = useSelector(selectShopProduct);
  const productName = productNameData?.resultProduct;
  const [isLoading, setLoading] = useState(true);
  const addCartData = useSelector(selectProduct);
  const addCartValue = addCartData?.resultAddCart;
  const isClearCart = addCartData?.isClearCart;
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const params = useParams();
  const token = getToken();
  const dispatch = useDispatch();
  const statePopUp = useSelector((state) => state?.cart?.popupState);

  useEffect(() => {
    $(document).ready(function () {
      if (statePopUp === true) {
        $(addressModalReference.current).modal("show");
      }
      $("#shopModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    });
  }, [statePopUp]);

  function goto() {
    $(addressModalReference.current).modal("hide");
    navigate("/sign-in");
  }

  function cancel() {
    $(addressModalReference.current).modal("hide");
    dispatch(setIsPopupModal(false));
  }

  useEffect(() => {
    const currentURL = window.location.href;
    const forPage = currentURL.split("/").slice(-2)[0];
    const forId = currentURL.split("/").slice(-1)[0];
    const pageSpit = currentURL.split("/").slice(-3)[0];
    if (pageSpit === "") {
      const urlCurrentPage = ``;
      setPageUrl(urlCurrentPage);
    } else {
      const urlCurrentPage = `${forPage}/${forId}`;
      setPageUrl(urlCurrentPage);
    }
    setLoading(true);
    dispatch(getProductShops({ shopId: params.id }));
  }, [dispatch, params.id]);

  useEffect(() => {
    setLoading(true);
    if (token) {
      dispatch(getShopByProduct({ shopId: params.id, pageno: currentPage }));
    } else {
      if (getLat() && getLong()) {
        dispatch(
          getGuestShopByProduct({
            shopId: params.id,
            pageno: currentPage,
            lat: getLat(),
            long: getLong(),
          })
        );
      } else {
        function showPosition(position) {
          dispatch(
            getGuestShopByProduct({
              shopId: params.id,
              pageno: currentPage,
              lat: position?.coords?.latitude,
              long: position?.coords?.longitude,
            })
          );
        }
        navigator?.geolocation?.getCurrentPosition(
          showPosition,
          (positionError) => {
            if (positionError) {
              toasterService(
                toasterType.danger,
                positionError?.message +
                ". Please manually allow location in your browser."
              );
            }
          },
          { enableHighAccuracy: true }
        );
      }
    }
  }, [dispatch, currentPage, params.id, token]);

  useEffect(() => {
    if (
      shopNameData?.status === "complete" &&
      productNameData?.status === "complete" &&
      addCartData?.status === "complete"
    ) {
      setLoading(false);
    }
  }, [shopNameData?.status, productNameData?.status, addCartData?.status]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isClearCart) {
      setIsShown(true);
    }
  }, [isClearCart]);

  const recordPerPage = 10;
  const pageRange = 10;

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    token
      ? dispatch(getShopByProduct({ shopId: params.id, pageno: pageNumber }))
      : dispatch(
        getGuestShopByProduct({ shopId: params.id, pageno: pageNumber })
      );
  };

  function setCartStatus() {
    dispatch(setIsClearCart(false));
  }

  // function tConvert(time) {
  //   if (time) {
  //     // Check correct time format and split into components
  //     time = time
  //       .toString()
  //       .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  //     if (time.length > 1) {
  //       // If time format correct
  //       time = time.slice(1); // Remove full string match value
  //       time[5] = +time[0] < 12 ? " am" : " pm"; // Set AM/PM
  //       time[0] = +time[0] % 12 || 12; // Adjust hours
  //     }
  //     return time.join(""); // return adjusted time or original string
  //   } else {
  //     return time;
  //   }
  // }

  async function clearCart() {
    await dispatch(
      addCart({
        product_id: addCartValue?.payload?.product_id,
        quantity: 1,
        shop_id: params?.id,
        is_clear: true,
      })
    );
    if (params.id) {
      await dispatch(
        getShopByProduct({ shopId: params.id, pageno: currentPage })
      );
    }
    await dispatch(getCartCountASyncThunk());
  }

  const map = (
    <RestaurantGoogleMap
      origin={{
        lat:
          shopName?.data[0] !== undefined
            ? shopName?.data[0]?.address?.latitude
            : "",
        lng:
          shopName?.data[0] !== undefined
            ? shopName?.data[0]?.address?.longitude
            : "",
      }}
      googleMapsApiKey={googleMapsApiKey}
      shopName={shopName?.data[0] !== undefined ? shopName?.data[0]?.name : ""}
      roundedStyle={true}
    />
  );

  return (
    <div>
      {isLoading ? (
        <div className="bg-white mt-5 pt-5 page-min-height">
          <div className="container mt-5 pt-5 rs-page">
            <div className="row">
              <Pane>
                <Spinner marginX="auto" marginY={120} />
              </Pane>
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-white page-min-height">
          <div className="container mt-5 pt-5 rs-page">
            <div className="row">
              <div className="col-12 col-md-3">
                <img
                  className="img-fluid d-block rs-page br-8"
                  src={
                    shopName?.data[0] !== undefined &&
                      shopName?.data[0]?.shop_image
                      ? shopName?.data[0]?.shop_image
                      : Rectangle5
                  }
                  alt="Shop Image"
                  style={{ height: "260%", width: "261%" }}
                />
              </div>
              <div className="col-12 col-md-6 mb-5">
                {shopName?.data[0]?.address !== undefined ? (
                  <>
                    <h1 className="pb-1">{shopName?.data[0].name}</h1>
                    <div className="pb-2">
                      {shopName?.data[0]?.cuisine &&
                        shopName?.data[0]?.cuisine?.length > 0 ? (
                        shopName?.data[0]?.cuisine.map((singleCuisine, key) => {
                          return (
                            <span
                              key={key}
                              className="badge badge-pill bg-edr-green text-white edr-card-radius fs-7 ms-1 me-1"
                            >
                              {singleCuisine?.name}
                            </span>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </div>

                    <p>
                      {shopName?.data[0]?.address?.house +
                        ", " +
                        shopName?.data[0]?.address?.area +
                        ", " +
                        shopName?.data[0]?.address?.locality +
                        ", " +
                        shopName?.data[0]?.address?.city}
                    </p>
                    <h6>{shopName?.data[0]?.open_message}</h6>
                    {/* todo: shop info distance to be added by @abhay */}
                    {/* {shopName?.data[0]?.distance >= 0 ? (
                  <div className="float-end distance-hover-style">
                    <span
                      className="badge rounded-pill bg-warning text-dark"
                      style={{ width: "130px", height: "24px" }}
                    >
                      <WalkIcon> </WalkIcon>
                      {shopName?.data[0]?.distance > 20
                        ? `More than 20 Km`
                        : `${shopName?.data[0]?.distance}Km`}
                    </span>
                  </div>
                ) : (
                  <></>
                )} */}

                    {shopName?.data[0]?.average_rating >= 0 ? (
                      <div className="float-start">
                        <span
                          className="badge rounded-pill bg-warning text-dark d-flex justify-content-center vertical-center"
                          style={{ width: "48px", height: "24px" }}
                        >
                          <StarEmptyIcon> </StarEmptyIcon>{" "}
                          {shopName?.data[0]?.average_rating}
                        </span>
                      </div>
                    ) : (
                      <></>
                    )}
                    {!shopName?.data[0]?.is_open ? (
                      <div className="float-end">
                        <span className="badge badge-pill bg-edr-red text-white edr-card-radius fs-7">
                          {shopName?.data[0]?.is_open === false
                            ? "Shop Closed"
                            : ""}
                        </span>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div
                className="col-12 col-md-3 br-8"
                style={{ minHeight: "240px" }}
              >
                {map}
              </div>
            </div>

            <div>
              <div
                className="modal fade"
                id="shopModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabelb"
                aria-hidden="true"
                ref={addressModalReference}
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body fs-3">
                      {notLoggedInErrorMessage}
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={(close) => {
                          cancel();
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn secondary-bg-color text-white"
                        onClick={(close) => {
                          goto();
                        }}
                      >
                        Login
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-md-12">
                {/* <h1 className="pb-5">
                  {shopName.data[0] !== undefined ? shopName.data[0].name : ""}
                </h1> */}
                {productName !== undefined
                  ? productName?.data[0]?.categoryArray?.map(
                    (category, key) => {
                      return (
                        <div key={key}>
                          <h5 className="fw-bold">{category.categoryName}</h5>
                          <div className="container">
                            <div className="row mb-5 mt-2">
                              {productName?.data !== undefined &&
                                productName?.status !== false
                                ? productName?.data[0]?.categoryArray[
                                  key
                                ]?.products?.map((product, key) => {
                                  return (
                                    <Product
                                      key={key}
                                      productName={
                                        product?.product_id?.name
                                      }
                                      productImage={
                                        product?.product_id?.product_image
                                      }
                                      price={product?.price}
                                      offerPrice={product?.final_price}
                                      productId={product?._id}
                                      shopId={product?.shop_id}
                                      cartQuantity={product?.cart_quantity}
                                      outOfStock={product?.out_of_stock}
                                      isOpen={product?.shop?.is_open}
                                      shopName={product?.shop?.name}
                                      pageNumber={currentPage}
                                      discounted={product?.discount}
                                      discount={product?.discount}
                                      discountType={product?.discount_type}
                                      isDeliverable={product?.isDeliverable}
                                      distance={product?.distance}
                                    />
                                  );
                                })
                                : ""}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )
                  : ""}
              </div>
            </div>
          </div>

          <Pane>
            <Dialog
              isShown={isShown}
              title="Cart is not empty"
              confirmLabel="Clear Cart"
              onConfirm={(close) => {
                close();
                clearCart(true);
              }}
              onCloseComplete={() => {
                setIsShown(false);
                setCartStatus();
              }}
            >
              <span className="fs-6">{addCartValue?.message}</span>
            </Dialog>
          </Pane>

          <div className="container mt-5">
            <div className="row my-5"></div>
            <div className="bg-white">
              <div className="col-md-12 d-flex justify-content-center flex-row">
                <Pagination
                  itemClass="page-item" // add it for bootstrap 4
                  linkClass="page-link" // add it for bootstrap 4
                  activePage={currentPage}
                  itemsCountPerPage={recordPerPage}
                  totalItemsCount={
                    // {productName?.data[0]?.totalCount?.total_rows}
                    productName.data?.length !== 0
                      ? productName.data[0]?.totalCount?.total_rows
                      : 0
                  }
                  pageRangeDisplayed={pageRange}
                  onChange={handlePageChange}
                />
              </div>
            </div>
            {/* <div className="row" style={{'marginTop':'150px'}}>
                  <div className="col-md-12 float-start">
                    <h2 className="fw-bold">Reviews</h2>
                  </div>
                </div> */}
          </div>
          <div className="container pb-5 "></div>
        </div>
      )}
      <FloatingCartComponent />
    </div>
  );
};
export default Restaurant;
