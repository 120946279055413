import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiRunner } from "../../services/communicator";
import { postRatingJson } from "../../services/standard";
import { toasterService, toasterType } from "../../services/toasterService";

export const postRating = createAsyncThunk(
  "rating/postRating",
  async (obj) => {
    const requestOptions = postRatingJson;
    requestOptions.url = requestOptions.url + "/" + obj.orderId;
    requestOptions[`body`] = JSON.stringify(obj);
    const response = await ApiRunner(requestOptions);
    toasterService(
      response.status === true ? toasterType.success : toasterType.danger,
      response?.message
    );
    return response;
  }
);

export const ratingSlice = createSlice({
  name: "rating",
  initialState: {
    status: "idle",
    rating: {},
  },

  extraReducers: (builder) => {
    builder
      .addCase(postRating.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postRating.fulfilled, (state, action) => {
        state.status = "complete";
        state.rating = action.payload;
      });
  },
});

export const selectRating = (state) => state.rating;
export default ratingSlice.reducer;