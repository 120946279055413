import { useEffect } from "react";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="container-fluid bg-white mt-5 pt-5 page-min-height ">
        <div className="row">
          <div className="col-md-12 ">
            <div className="container-fluid mt-5 mb-5">
              <h2 className="PrivacyHeader">PRIVACY POLICY</h2>
              <p className="TextHederprivacy">
                At Eat and drink Restaurant we respect your concerns about
                privacy. This Privacy Statement (“Statement”) describes the
                personal information practices of Eat and drink with respect to
                information about our customers and others who visit our
                restaurants, Web sites and mobile apps, including the types of
                personal information we collect, how we may use this
                information, and with whom we may share it.{" "}
              </p>
              <h5 className="PrivacyHeader1">1.Information We Collect</h5>
              <p className="TextHederprivacy">
                We may collect personal information about you when you visit one
                of our restaurants, use Eat and drink Web sites (“Sites”), or
                use our web or mobile device applications (“Mobile Apps”). We
                refer to our Sites and Apps collectively as “Online Services”.{" "}
              </p>
              <h6 className="TextHederprivacy">
                The types of personal information we collect may include:
              </h6>
              <ul>
                <li className="TextHederprivacy">
                  Transaction information, such as product purchased, price,
                  method of payment and payment details.
                </li>
                <li className="TextHederprivacy">
                  Personal details such as name, postal address, email address,
                  phone number, date of birth and other contact information when
                  you provide that information to us, such as if you register
                  with our Online Services or enter an Eat and drink s contest,
                  competition, prize draw, or sweepstakes.
                </li>
                <li className="TextHederprivacy">
                  Account information, such as username, password, and other
                  identifiers or credentials used to access our Online Services
                  or to acquire Eat and drink products and services.
                </li>
                <li className="TextHederprivacy">
                  Other personal information you choose to provide when you
                  interact with Eat and drink
                </li>
              </ul>
              <h6 className="TextHederprivacy">
                The circumstances in which you provide personal information may
                include when you:
              </h6>
              <ul>
                <li className="TextHederprivacy">
                  purchase products at our restaurants;
                </li>
                <li className="TextHederprivacy">
                  register to use our Online Services;
                </li>
                <li className="TextHederprivacy">
                  request to receive marketing or other communications;
                </li>
                <li className="TextHederprivacy">
                  submit information when providing feedback; or
                </li>
                <li className="TextHederprivacy">
                  use interactive features of our Online Services.{" "}
                </li>
              </ul>
              <h6 className="TextHederprivacy">
                Information Collected Through Automated Means
              </h6>
              <p className="TextHederprivacy">
                We may collect information from your computer system or mobile
                device through automated technologies when you visit our
                restaurants or use our Online Services or in-restaurant
                technologies. These automated technologies may include cookies,
                local shared objects, and web beacons. Additional information
                regarding the use of cookies and other automated technologies is
                provided in this Statement below. The information we collect may
                include your Internet Protocol (IP) address; computer/mobile
                device operating system and browser type; type of mobile device;
                mobile device settings; the unique device identifier (UDID) or
                mobile equipment identifier (MEID) for your mobile device;
                device and component serial numbers; advertising identifier
                (e.g., IDFAs and IFAs) or similar identifier; the referring
                website or application; the linked sites, third-party websites,
                applications or social media where you share Online Services
                content; and activity related to your use of our Online Services
                such as the pages you visit on our Sites or in our Mobile Apps.
              </p>
              <h6 className="TextHederprivacy">Location Information</h6>
              <p className="TextHederprivacy">
                Eatanddrink Online Services and in-restaurant technologies may
                collect precise information about the location of your mobile
                device or computer using geolocation and location presence
                awareness technologies such as GPS, Wi-Fi, Bluetooth, or cell
                tower proximity. For most mobile devices and computer systems,
                you are able to withdraw your permission for eatanddrink to
                acquire this information on your location through the device or
                Web browser settings. If you have questions about how to disable
                collection of precise location information, we recommend you
                contact your mobile device service provider, the device
                manufacturer, or your Web browser provider. Please note that
                certain Online Services and in-restaurant technologies may not
                function properly without precise location information. If you
                would like us to delete location information we have collected
                that is identifiable to you, please contact us at the email or
                postal address listed below. Please note that we may be required
                by law to retain certain information.
              </p>
              <p className="TextHederprivacy">
                Combined Information We may combine personal information that we
                collect with information we receive from other sources,
                information about your prior transactions with Eat and drink and
                information that is automatically collected.
              </p>
              <h5 className="TextHederprivacy">
                2. How We Use the Information We Collect
              </h5>
              <h6 className="TextHederprivacy">
                Eat and drink may use the personal information we collect to:
              </h6>
              <p className="TextHederprivacy">
                fulfil your requests or orders and process payments for our
                products and services;
              </p>
              <p className="TextHederprivacy">
                if you give us permission, communicate with you about products
                and services of Eat and drink or our business partners that we
                believe may interest you;
              </p>
              <ul>
                <li className="TextHederprivacy">
                  communicate with you about and administer your participation
                  in contests, competitions, prize draws, sweepstakes, offers,
                  promotions or special events;
                </li>
                <li className="TextHederprivacy">
                  send communications to you about your orders, purchases or
                  accounts with Eat and drink;{" "}
                </li>
                <li className="TextHederprivacy">
                  send information to your contacts if you request us to do so;
                </li>
                <li className="TextHederprivacy">
                  provide customer support, respond to, and communicate with you
                  about your requests, questions and comments;
                </li>
                <li className="TextHederprivacy">
                  enable you to post on our blogs and interact with Eat and
                  drink through social media;
                </li>
                <li className="TextHederprivacy">
                  personalize your experience on our Online Services and
                  in-restaurant technologies, such as providing you with content
                  in which you may be interested, and making navigation to our
                  Sites and Mobile Apps easier;
                </li>
                <li className="TextHederprivacy">
                  perform data analytics, including consumer research, trend
                  analysis, and financial analysis;
                </li>
                <li className="TextHederprivacy">
                  operate, evaluate and improve our business, including the
                  development of new products and services; determination of the
                  effectiveness of our sales, marketing and advertising efforts;
                  and analysis and improvement of our products, offers,
                  promotions, and Online Services and other technologies;
                </li>
                <li className="TextHederprivacy">
                  protect against, identify and prevent fraud and other criminal
                  activity, claims and other liabilities; and
                </li>
                <li className="TextHederprivacy">
                  comply with applicable law.
                </li>
              </ul>
              <p className="TextHederprivacy">
                We use automated information for purposes such as to (i)
                personalize the visitor experience in our restaurants or on our
                Online Services, (ii) deliver content (including advertising)
                tailored to our users' interests and the manner in which our
                users browse our Online Services or in-restaurant technologies,
                and (iii) manage our business. We also use this information to
                help diagnose technical and service problems; administer our
                Online Services; identify users of our Online Services; identify
                a device for fraud prevention purposes, to target advertising or
                to personalize users’ experiences; and gather demographic
                information about our users. We also use automated information
                to determine usage patterns and how we may tailor our Online
                Services to better meet the needs of our users.We may use the
                information we obtain about you in other ways for which we
                provide specific notice at the time of collection.
              </p>
              <h5 className="TextHederprivacy">
                3. How We Share the Personal Information We Collect
              </h5>
              <p className="TextHederprivacy">
                Eat and drink does not sell or otherwise share personal
                information about you, except as described in this Privacy
                Statement.{" "}
              </p>
              <p className="TextHederprivacy">
                Eat and drink may share personal information within the Eat and
                drink Family. Eat and drink Family includes Eat and drink Group,
                its affiliates, subsidiaries, suppliers, vendors, and
                franchisees. Members of the Eat and drink Family that receive
                this information from Eat and drink are not authorized to use or
                disclose the information except as provided in this Privacy
                Statement.{" "}
              </p>
              <p className="TextHederprivacy">
                Eat and drink may share personal information with vendors that
                provide services to Eat and drink Restaurant such as fulfilling
                orders, providing data processing and other information
                technology services, administering promotions, contests, prize
                draws and sweepstakes, conducting research and performing
                analytics, and personalizing individual Eat and drink
                experiences. We do not authorize these vendors to use the
                personal information or to disclose it for any purpose other
                than to perform services on our behalf.
              </p>
              <p className="TextHederprivacy">
                Circumstances may arise where, whether for strategic or other
                business reasons, Eat and drink decides to sell or transfer all
                or a portion of our business. We may transfer information we
                have collected and stored, including personal information, to
                the party or parties involved in the transaction as part of that
                transaction.
              </p>
              <p className="TextHederprivacy">
                Eat and drink reserves the right to use or disclose personal
                information as needed to satisfy any law, regulation or legal
                request, to protect the integrity of our Online Services and
                in-restaurant technologies, to bring or defend against legal
                claims, to protect the rights, interests, safety and security of
                Eat and drink Restaurant employees or franchisees, or members of
                the public, or in connection with the investigation of fraud,
                illegal activity or violation of Eat and drink policy.
              </p>
              <h5 className="TextHederprivacy">4. Children's Privacy Notice</h5>
              <p className="TextHederprivacy">
                Eat and drink understands the importance of privacy of users of
                our Online Services. We are especially committed to protecting
                the privacy of children who visit or use our Online Services.
                This Children's Privacy Notice is designed to answer your
                questions regarding our practices with respect to personal
                information we collect through our Online Services from children
                under the age of [13].
              </p>
              <h6 className="TextHederprivacy">
                1.What Personal Information Eat and drink Collect?
              </h6>
              <ul>
                <li className="TextHederprivacy">
                  We may collect a child's email address to notify the child if
                  he or she has won or lost a contest, competition, prize draw
                  or sweepstakes. In that event, we will notify the child using
                  the email address the child supplies. We will not keep the
                  email address or use it for any other purpose.
                </li>
                <li className="TextHederprivacy">
                  We may collect a child's email address in order to respond to
                  a request from the child on a one-time basis, such as to send
                  the child a screen saver. In this case we promptly delete the
                  email address from our system once we have responded, and we
                  do not use the address for any other purpose.
                </li>
              </ul>
              <p className="TextHederprivacy">
                We will not collect other personal information from a child
                under the age of [13], such as a [postal address] or telephone
                number, without the consent of a parent or guardian. If parent
                or guardian consent is required, we will collect the email
                address of the parent or guardian so we can provide notice of
                our information practices and/or seek the parent's or guardian's
                consent.
              </p>
              <p className="TextHederprivacy">
                Eat and drink will not condition a child's participation in any
                online activity on the child's disclosure of more personal
                information than is reasonably necessary to participate in that
                activity.
              </p>
              <h6 className="TextHederprivacy">
                2.Eat and drink Use the Personal Information It Collects?
              </h6>
              <p className="TextHederprivacy">
                We use email addresses submitted by a child in order to respond
                to the child's request or to notify the child if he or she has
                won or lost a contest, competition, prize draw, or sweepstakes
                they entered. We use the email address of a parent or guardian
                in order to provide the parent notice of our information
                practices or to seek the parent's or guardian's consent.
              </p>
              <h6 className="TextHederprivacy">
                3.How Eat and drink Share Personal Information?
              </h6>
              <p className="TextHederprivacy">
                We may share a child's personal information with third parties
                to the extent reasonably necessary to protect the security or
                integrity of our Online Services, take precautions against
                liability, or respond to judicial process or requests from other
                governmental authorities, or when we believe disclosure is
                necessary or appropriate to prevent physical harm or financial
                loss, or in connection with an investigation of suspected or
                actual illegal activity.
              </p>
              <h6 className="TextHederprivacy">
                4.Requests from Parents Regarding Our Children's Privacy
                Practices.
              </h6>
              <p className="TextHederprivacy">
                Parents may review personal information we have collected from
                their child that has not been deleted, and can request that the
                personal information be deleted from our records if it has not
                been already. Parents also may refuse to permit further
                collection or use of their child's personal information, when
                the parent has previously provided consent. To do so, please
                contact us at the address, telephone number or email address
                provided below with your request.
              </p>
              <h6 className="TextHederprivacy">
                5.Who Should I Contact with Questions about Children’s Privacy?
              </h6>
              <p className="TextHederprivacy">
                We urge parents to regularly monitor and supervise their
                children's online activities. If you have any questions about
                our children’s privacy practices, please contact us at:
              </p>
              <p className="TextHederprivacy">
                Privacy at Eat and drink Restaurant LLC Dubai 65892.
              </p>
              <p className="TextHederprivacy">
                Email us at info@eatanddrink.ae
              </p>
              <h6 className="TextHederprivacy">
                6.Access to and Correction and Deletion of Personal Information
              </h6>
              <p className="TextHederprivacy">
                If at any time you would like to access, correct, or delete
                personal information we have collected from you, please contact
                us at the email or postal address listed below. In certain
                circumstances, including when required by applicable law, we
                will comply with your request. Please note that we may be
                required by law to retain certain information. Before Eat and
                drink is able to provide you with any information or correct any
                inaccuracies, we may ask you to verify your identity and/or
                provide other details to help us respond to your request.{" "}
              </p>
              <h6 className="TextHederprivacy">
                7.Cookies and Similar Technologies
              </h6>
              <p className="TextHederprivacy">
                Eat and drink uses cookies and other similar technologies to
                collect information for the purposes described in this Privacy
                Statement under “How We Use the Information We Collect.”{" "}
              </p>
              <p className="TextHederprivacy">Cookies</p>
              <p className="TextHederprivacy">
                A "cookie" is a text file that websites send to a visitor's
                computer or other Internet-connected device to uniquely identify
                the visitor's browser or to store information or settings in the
                browser. Like most websites, Eat and drink uses cookies for a
                variety of purposes in order to improve your online experience.
                For example, we track the total number of visitors to our Sites
                on an anonymous aggregate basis. We may also employ cookies so
                that we remember you when you return to the Site, to keep track
                of the items in your shopping cart if you purchase merchandise
                online, to administer certain contests and sweepstakes and to
                help customize your Eat and drink web experience. We may
                associate personal information with a cookie file in those
                instances. Both we and others (such as out advertising networks)
                may collect personal information about our visitors’ online
                activities, overtime and across third-party websites, when using
                our Online services.
              </p>
              <p className="TextHederprivacy">
                Use the options in your web browser if you do not wish to
                receive a cookie or if you wish to set your browser to notify
                you when you receive a cookie. Click on the “Help” section of
                your browser to learn how to change your cookie preferences. If
                you disable all cookies, you may not be able to take advantage
                of all the features of this Site.{" "}
              </p>
              <p className="TextHederprivacy">Other Technologies</p>
              <p className="TextHederprivacy">
                We may use other technologies, including web beacons and
                JavaScript, which sometimes work together with cookies and other
                means with our Sites and Mobile Apps to uniquely identify your
                device. These other technologies help enable features on our
                Sites and within our Mobile Apps.
              </p>
              <p className="TextHederprivacy">
                We may also use certain technologies to determine whether you
                have opened an e-mail or clicked on a link contained in an
                e-mail.
              </p>
              <h5 className="TextHederprivacy">Please note the following:</h5>
              <ul>
                <li className="TextHederprivacy">
                  We offer certain features that are available only through the
                  use of cookies and other similar technologies
                </li>
                <li className="TextHederprivacy">
                  We use both session and persistent cookies and other tracking
                  technologies. Tracking technologies (e.g., cookies) can either
                  be persistent (i.e., they remain on your computer until you
                  delete them) or session-based (i.e., they last only until you
                  close your browser). You are always free to decline tracking
                  technologies if your browser permits, although doing so may
                  interfere with your use of Online Services.
                </li>
                <li className="TextHederprivacy">
                  We may encode and protect the tracking cookies and other
                  tracking technologies that Eat and drink sets, so that only we
                  can interpret the information stored in them.
                </li>
              </ul>
              <h6 className="TextHederprivacy">8.Targeted Advertising</h6>
              <p className="TextHederprivacy">
                On our Online Services, we may collect information about your
                online activities for use in providing you with advertising
                tailored to your individual interests. This section of our
                Privacy Statement provides details and explains how to exercise
                your choices.
              </p>
              <p className="TextHederprivacy">
                You may see certain ads on other websites because we participate
                in advertising networks. Ad networks allow us to target our
                messaging to users through demographic, interest-based and
                contextual means. These networks track your online activities
                over time by collecting information through automated means,
                including through the use of cookies, web server logs, web
                beacons and other technologies. The networks use this
                information to show you advertisements that may be tailored to
                your individual interests. The information our ad networks may
                collect includes information about your visits to websites that
                participate in the relevant advertising networks, such as the
                pages or advertisements you view and the actions you take on the
                websites. This data collection takes place both on our Online
                Services and on third-party websites that participate in the ad
                networks. This process also helps us evaluate our marketing
                efforts. You can opt out of delivery of targeted advertising to
                you by multiple companies by visiting:
              </p>
              <p className="TextHederprivacy">•www.aboutads.info/choices,</p>
              <p className="TextHederprivacy">
                Please note that even if you opt out, you will continue to
                receive advertisements, but they will not be tailored to your
                specific interests.
              </p>
              <h6 className="TextHederprivacy">9. Information Security</h6>
              <p className="TextHederprivacy">
                Eat and drink is committed to taking appropriate measures
                designed to keep personal information secure. We have in place
                technical, administrative, and physical procedures designed to
                protect personal information from accidental, unlawful or
                unauthorized loss, access, disclosure, use, alteration, or
                destruction. While we take efforts to safeguard our information
                systems, no website, mobile application, computer system, or
                transmission of data over the Internet or any other public
                network can be guaranteed to be 100% secure.
              </p>
              <h6 className="TextHederprivacy">
                10. Retention of Personal Information
              </h6>
              <p className="TextHederprivacy">
                We retain personal information for the period of time necessary
                to fulfil the purposes outlined in this Privacy Statement and
                per Eat and drink records retention policies unless a longer
                retention period is required or permitted by law.{" "}
              </p>
              <h6 className="TextHederprivacy">
                12.Changes to our Privacy Statement
              </h6>
              <p className="TextHederprivacy">
                We may change this Privacy Statement from time to time. If we
                change the Privacy Statement, we will post the revised version
                here and change the “Effective Date” listed at the top of this
                Statement. We encourage you to check here periodically for the
                most up-to-date version of our Statement.{" "}
              </p>
              <h6 className="TextHederprivacy">13. How to Contact Us</h6>
              <p className="TextHederprivacy">
                Privacy at Eat and drink Restaurant LLC Dubai 65892.
              </p>
              <p className="TextHederprivacy">
                Email us at info@eatanddrink.ae
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
