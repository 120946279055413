import React, { useState, useEffect } from "react";
import street_food from "../images/street_food.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { postSignup, signUpUser } from "../features/sign-in/signupSlice";
import { Pane, Spinner } from "evergreen-ui";
import { toasterService, toasterType } from "../services/toasterService";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import OtpField from "react-otp-field";

const SignUp = () => {
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [mobile, setMobile] = useState("");
  const [countryCode, setCountryCode] = useState("+971");
  const [mobileError, setMobileError] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [isRevealOtp, setIsRevealOtp] = useState(false);
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  let loggedInUser = useSelector(signUpUser);
  useEffect(() => {
    setLoading(false);
    if (Object.keys(loggedInUser).length !== 0) {
      if (loggedInUser?.status) {
        setIsRevealOtp(true);
      }
    }
  }, [dispatch, loggedInUser]);

  const handleSubmit = (e) => {
    e?.preventDefault();
    let error = false;
    const message = {
      name: "Name Not Found",
      mobile: "Mobile Not Found",
      valid: "Please enter valid mobile number",
      email: "Email Not Found",
      otp: "Please enter OTP",
      password: "Password Not Found",
    };

    if (name === "") {
      toasterService(toasterType.danger, message?.name);
      setNameError(true);
      error = true;
    } else {
      setNameError(false);
    }

    const isMobileValid = new RegExp(/^[0-9\b]+$/);
    if (mobile === "") {
      toasterService(toasterType.danger, message?.mobile);
      setMobileError(true);
      error = true;
    } else if (!isMobileValid) {
      toasterService(toasterType.danger, message?.valid);
      error = true;
    } else if (String(mobile).length <= 5) {
      toasterService(toasterType.danger, message?.valid);
      error = true;
    } else {
      setMobileError(false);
    }

    const isEmailValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
      email
    );
    if (!isEmailValid) {
      if (email === "") {
        setEmailError(true);
      }
      toasterService(toasterType.danger, message?.email);
      error = true;
    } else {
      setEmailError(false);
    }
    if (isRevealOtp) {
      if (otp === "") {
        toasterService(toasterType.danger, message?.otp);
        setOtpError(true);
        error = true;
      } else if (String(otp).length < 4) {
        toasterService(toasterType.danger, message?.valid);
        error = true;
      } else {
        setOtpError(false);
      }
    }
    if (error === true) {
      return;
    }

    setLoading(true);
    if (isRevealOtp) {
      dispatch(
        postSignup({
          name: name,
          email: email.toLowerCase(),
          mobile: mobile,
          otp: otp,
          countryCode: countryCode,
        })
      );
    } else {
      dispatch(
        postSignup({
          name: name,
          email: email.toLowerCase(),
          mobile: mobile,
          countryCode: countryCode,
        })
      );
    }
  };

  const resendOTP = (e) => {
    setIsRevealOtp(false);
    e?.preventDefault();
    let error = false;
    const message = {
      name: "Name Not Found",
      mobile: "Mobile Not Found",
      valid: "Please enter valid mobile number",
      email: "Email Not Found",
      otp: "Please enter OTP",
      password: "Password Not Found",
    };

    if (name === "") {
      toasterService(toasterType.danger, message?.name);
      setNameError(true);
      error = true;
    } else {
      setNameError(false);
    }

    const isMobileValid = new RegExp(/^[0-9\b]+$/);
    if (mobile === "") {
      toasterService(toasterType.danger, message?.mobile);
      setMobileError(true);
      error = true;
    } else if (!isMobileValid) {
      toasterService(toasterType.danger, message?.valid);
      error = true;
    } else if (String(mobile).length <= 5) {
      toasterService(toasterType.danger, message?.valid);
      error = true;
    } else {
      setMobileError(false);
    }

    const isEmailValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
      email
    );
    if (!isEmailValid) {
      if (email === "") {
        setEmailError(true);
      }
      toasterService(toasterType.danger, message?.email);
      error = true;
    } else {
      setEmailError(false);
    }
    if (error === true) {
      return;
    }

    setLoading(true);
    dispatch(
      postSignup({
        name: name,
        email: email.toLowerCase(),
        mobile: mobile,
        countryCode: countryCode,
      })
    );
  };

  return (
    <div>
      <div className="container-fluid bg-white mt-5 pt-5 page-min-height">
        <div className="row">
          <div className="col-md-12 ">
            <div className="container-fluid mt-5 mb-5">
              <div className="row">
                <div className="col-md-6 edr-bg-gray edr-signup-form-radius p-md-5 p-sm-3">
                  <div className="col-md-9">
                    <h5 className="text-edr-primary mb-4">
                      Sign-up to continue
                    </h5>
                    <form onSubmit={(e) => handleSubmit(e)}>
                      <div className="mb-3">
                        <label
                          htmlFor="name"
                          className="form-label edr-dft-text-gray"
                        >
                          Name
                        </label>
                        <input
                          type="text"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          className="form-control text-line"
                          id="name"
                          placeholder="Enter Name"
                        />
                        {nameError === true ? (
                          <div className="text-danger">Name is not found.</div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="email"
                          className="form-label edr-dft-text-gray"
                        >
                          Email address
                        </label>
                        <input
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="form-control text-line"
                          id="email"
                          placeholder="Enter Email"
                        />
                        {emailError === true ? (
                          <div className="text-danger">Email is not found.</div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="mobile"
                          className="form-label edr-dft-text-gray"
                        >
                          Mobile
                        </label>
                        <div class="d-flex">
                          <IntlTelInput
                            preferredCountries={["ae"]}
                            inputClassName="form-control text-line-mobile"
                            onSelectFlag={(
                              currentNumber,
                              selectedCountryData,
                              fullNumber,
                              isValid
                            ) => {
                              setCountryCode(
                                `${selectedCountryData?.dialCode}`
                              );
                            }}
                          />
                          <input
                            className="form-control text-line"
                            id="mobile"
                            onChange={(e) => setMobile(e.target.value)}
                            required
                          ></input>
                        </div>
                        {mobileError === true ? (
                          <div className="text-danger">
                            Mobile is not found.
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div>
                        {isRevealOtp === true ? (
                          <div className="mb-3">
                            <label
                              htmlFor="otp"
                              className="form-label edr-dft-text-gray"
                            >
                              OTP
                            </label>
                            <div className="input-group mb-3">
                              <OtpField
                                value={otp}
                                onChange={setOtp}
                                numInputs={4}
                                onChangeRegex={/^([0-9]{0,})$/}
                                autoFocus
                                separator={<span></span>}
                                isTypeNumber
                                inputProps={{
                                  className: "otp-style",
                                  disabled: false,
                                }}
                              />
                            </div>
                            {otpError === true ? (
                              <div className="text-danger">
                                Please enter OTP
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                        <div className="mb-5">
                          <span className="password-link" onClick={resendOTP}>
                            {" "}
                            Resend OTP?
                          </span>
                        </div>
                      </div>
                      <div className="mb-3">
                        {isLoading ? (
                          <Pane>
                            <Spinner marginX="auto" marginY={120} />
                          </Pane>
                        ) : (
                          <button className="btn secondary-bg-color text-white edr-card-radius width-full">
                            Sign up
                          </button>
                        )}
                      </div>
                    </form>
                    <div>
                      <p className="edr-dft-text-gray">
                        Have an account{" "}
                        <Link to="/sign-in" className="text-edr-primary">
                          <span className="SignInText">Sign In</span>
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 secondary-bg-color edr-dft-image-radius p-5">
                  <img alt="" className="img-fluid center" src={street_food} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      \
    </div>
  );
};

export default SignUp;
