import { Pane, Spinner } from 'evergreen-ui';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteAddress, getPrimaryAddress, setPrimary } from '../features/restaurant/addressSlice';

const AddressCard = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deleteLoading, setDeleteLoading] = useState(false);

  const setPrimaryAddress = (id) => {
    dispatch(setPrimary({ addressId: id }));
  };

  async function dropAddress(id) {
    await setDeleteLoading(true);
    await dispatch(
      deleteAddress({
        addressId: id,
      })
    );
    await dispatch(getPrimaryAddress());
    await setDeleteLoading(false);
  }

  function Edit(id) {
    navigate(`/changeaddress/${id}`);
  }

  return (
    <div className="col-sm-6 col-lg-4">
      <div className="cards">
        <div className="cards-content text-center">
          <div className="card-body">
            {props?.address !== undefined && "primary" in props?.address ? (
              <p className="card-text">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-geo-alt-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                </svg>
                {` ${props?.address?.house}, ${props?.address.area}, ${props?.address?.locality}, ${props?.address?.city}`}
              </p>
            ) : (
              <></>
            )}
            {props?.address?.primary ? (
              <div className="btn text-info">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="33"
                  height="28"
                  fill="currentColor"
                  className="bi bi-check2-all"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z" />
                  <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z" />
                </svg>
                Default Address
              </div>
            ) : (
              <button
                className="btn secondary-bg-color text-white"
                onClick={() => {
                  setPrimaryAddress(props?.address?._id);
                }}
              >
                Set Primary Address
              </button>
            )}{" "}
            {deleteLoading ? (
              <button className="btn btn-danger m-2" disabled="true">
                <Pane>
                  <Spinner marginX="auto" />
                </Pane>
              </button>
            ) : (
              <button
                className="btn btn-danger m-2"
                onClick={() => {
                  dropAddress(props?.address?._id);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-trash"
                  viewBox="0 0 16 16"
                >
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                  <path
                    fillRule="evenodd"
                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                  />
                </svg>
              </button>
            )}
            <button
              className="btn btn-info "
              onClick={() => {
                Edit(props?.address?._id);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-pencil-square"
                viewBox="0 0 16 16"
              >
                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                <path
                  fillRule="evenodd"
                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddressCard;
