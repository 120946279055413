import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiRunner } from "../../services/communicator";
import {
  getGuestProductDetailsByIdJson,
  getGuestProductDetailsJson,
  getGuestSearchProductJson,
  getProductDetailsJson,
  getSearchProductJson,
} from "../../services/standard";

export const getSearchProduct = createAsyncThunk(
  "searchProduct/getSearchProduct",
  async (obj) => {
    const requestOptions = Object.assign({}, getSearchProductJson);
    requestOptions.url = `${getSearchProductJson.url}/${obj.lat}/${obj.long}?name=${obj.name}&page=${obj.pageno}`;
    const response = await ApiRunner(requestOptions);
    return response;
  }
);

export const getGuestSearchProduct = createAsyncThunk(
  "guestSearchProduct/getGuestSearchProduct",
  async (obj) => {
    const requestOptions = Object.assign({}, getGuestSearchProductJson);
    requestOptions.url = `${getGuestSearchProductJson.url}/${obj.lat}/${obj.long}?name=${obj.name}&page=${obj.pageno}`;
    const response = await ApiRunner(requestOptions);
    return response;
  }
);

export const getProductById = createAsyncThunk(
  "ProductById/getProductById",
  async (obj) => {
    const requestOptions = Object.assign({}, getProductDetailsJson);
    requestOptions.url = `${getProductDetailsJson.url}/${obj.productId}`;
    const response = await ApiRunner(requestOptions);
    return response;
  }
);

export const getGuestProductById = createAsyncThunk(
  "ProductById/getGuestProductById",
  async (obj) => {
    const requestOptions = Object.assign({}, getGuestProductDetailsByIdJson);
    requestOptions.url = `${getGuestProductDetailsByIdJson.url}/${obj.productId}`;
    const response = await ApiRunner(requestOptions);
    return response;
  }
);

export const getProductDetailsGuestJson = createAsyncThunk(
  "ProductById/getProductById",
  async (obj) => {
    const requestOptions = Object.assign({}, getGuestProductDetailsJson);
    requestOptions.url = `${getGuestProductDetailsJson.url}/${obj.productId}`;
    const response = await ApiRunner(requestOptions);
    return response;
  }
);

export const searchProductSlice = createSlice({
  name: "searchProduct",
  initialState: {
    status: "idle",
    result: { data: {} },
    resultb: { data: {} },
    ProductResultById: { data: {} },
    guestProductResultById: { data: {} },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getSearchProduct.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSearchProduct.fulfilled, (state, action) => {
        state.status = "complete";
        state.result = action.payload;
      })
      .addCase(getGuestSearchProduct.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getGuestSearchProduct.fulfilled, (state, action) => {
        state.status = "complete";
        state.result = action.payload;
      })
      .addCase(getProductById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getProductById.fulfilled, (state, action) => {
        state.status = "complete";
        state.ProductResultById = action.payload;
      })
      .addCase(getGuestProductById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getGuestProductById.fulfilled, (state, action) => {
        state.status = "complete";
        state.guestProductResultById = action.payload;
      });
  },
});
export const selectSearchProduct = (state) => state.searchProduct;

export default searchProductSlice.reducer;
